@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "~@ng-select/ng-select/themes/material.theme.css";
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: var(--website-font-family) !important;
}

html {
  scroll-behavior: smooth;

  --mdc-outlined-text-field-caret-color: #0C79B7;
  --mdc-outlined-text-field-focus-outline-color: #0C79B7;
  --mdc-outlined-text-field-focus-label-text-color: #0C79B7;
  --mdc-outlined-text-field-label-text-color: #747475;
  --mdc-outlined-text-field-disabled-label-text-color: #dcdde0;
  --mdc-outlined-text-field-input-text-color: #272e48;
  --mdc-outlined-text-field-disabled-input-text-color: #0C79B7;
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-outline-color: #d1d2d7;
  --mdc-outlined-text-field-disabled-outline-color: #dcdde0;
  --mdc-outlined-text-field-hover-outline-color: #0C79B7;
  --mat-form-field-disabled-input-text-placeholder-color: rgb(49 45 45 / 38%);

  --mat-option-label-text-color: #272e48;
  --mat-select-panel-background-color: #ffffff;
  --mat-option-selected-state-label-text-color: #1D5795;


  --mat-datepicker-calendar-date-selected-state-text-color: #272e48;
  --mat-datepicker-calendar-date-selected-state-background-color: #cecfd3;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: #7d7d7e;
  --mat-datepicker-calendar-date-today-selected-state-outline-color: #272e48;
  --mat-datepicker-calendar-date-focus-state-background-color: #cecfd3;
  --mat-datepicker-calendar-date-hover-state-background-color: #e9e9ec;
  --mat-datepicker-toggle-active-state-icon-color: #1D5795;
  --mat-datepicker-calendar-date-in-range-state-background-color: #cecfd3;
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: #272e48;
  --mat-datepicker-calendar-body-label-text-color: #272e48;
  --mat-datepicker-calendar-period-button-icon-color: #272e48;
  --mat-datepicker-calendar-navigation-button-icon-color: #272e48;
  --mat-datepicker-calendar-header-text-color: #272e48;
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: #272e48;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: #272e48;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: #272e48;
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.5);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-select-enabled-arrow-color: rgb(0 0 0 / 54%);

  --mat-select-enabled-trigger-text-color: #272e48;
  --mat-select-focused-arrow-color: #0C79B7;

  --mat-autocomplete-background-color: #ffffff;

  --mat-menu-item-label-text-color: #272e48;
  --mat-menu-item-icon-color: #272e48;
  --mat-menu-item-hover-state-layer-color: rgb(239, 239, 239);
  --mat-menu-item-focus-state-layer-color: rgb(150, 148, 148);
  --mat-menu-container-color: #ffffff;

  --mdc-outlined-text-field-label-text-font: var(--website-font-family);
  --mat-menu-item-label-text-font: var(--website-font-family);
  --mat-option-label-text-font: var(--website-font-family);
  --mat-select-trigger-text-font: var(--website-font-family);
  --mat-form-field-subscript-text-font: var(--website-font-family);

  --mat-menu-item-label-text-size: 15px;
}

:root {
  --fc-button-bg-color: #1D5795;
  --fc-button-border-color: #1D5795;
  --fc-button-hover-bg-color: #2d41bd;
  --fc-button-hover-border-color: #2d41bd;
  --fc-button-active-bg-color: #1f2c7a;
  --fc-button-active-border-color: #1f2c7a;
}

.mat-icon {
  height: auto !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #272e48;
}

.breadcrumb-slash {
  color: #272e48 !important;
  margin: 0 2px 0 2px;
  -moz-user-select: none;
  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}

.breadcrumb-path * {
  color: #272e48 !important;
}

.breadcrumb-path .last-item {
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
}

.breadcrumb-path {
  display: flex !important;
  align-items: center !important;
}
:root {
  --website-font-family: Roboto, sans-serif;
  --website-font-weight-normal: 200;
}

html {
  height: 100%
}

body {
  background-color: #f7f8f9;
  font-family: var(--website-font-family) !important;
  color: #272e48;
  font-size: .9375rem;
  height: 100%;
  line-height: 1.5;
  overflow-x: hidden
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--website-font-family) !important;
  margin-top: 0;
  color: #272e48;
  margin-bottom: 0
}

a:hover, a:active, a:focus {
  outline: none;
  text-decoration: none
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #fff inset !important
}

.form-control {
  border: 1px solid #dee2e6;
  box-shadow: none;
  padding: .54688rem .875rem;
  font-size: .875rem;
  height: calc(1.6em + 1.21875rem);
  line-height: 1.6;
  font-weight: var(--website-font-weight-normal);
  color: #1e2022
}

.form-control:focus {
  box-shadow: 0 0 10px rgba(136, 136, 136, .125);
  outline: 0;
  border-color: #c1c9d0
}

.form-control-sm {
  height: calc(1.5em + 1rem);
  padding: .4375rem .65625rem;
  font-size: .8125rem;
  line-height: 1.5;
  border-radius: .25rem
}

.form-control-lg {
  height: calc(1.5em + 1.875rem);
  padding: .875rem 1.09375rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .3125rem
}

a {
  color: #1D5795;
  text-decoration: none !important;
}

a:hover {
  color: #37699d;
  text-decoration: none
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

p:last-child {
  margin-bottom: 0
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 0
}

input, button, a {
  transition: all .4s ease;
  -moz-transition: all .4s ease;
  -o-transition: all .4s ease;
  -webkit-transition: all .4s ease
}

input, input:focus, button, button:focus {
  outline: none
}

input[type=file] {
  height: auto;
  min-height: calc(1.5em + .75rem + 2px)
}

input[type=text], input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

textarea.form-control {
  resize: vertical
}

a {
  cursor: pointer
}

.navbar-nav > li {
  float: left
}

.form-group {
  margin-bottom: 15px;
  position: relative
}

.form-group img {
  width: 100%
}

.form-group .addon {
  position: absolute;
  top: 30%;
  right: 20px
}

.input-group .form-control {
  height: 40px
}

.nav .open > a, .nav .open > a:focus, .nav .open > a:hover {
  background-color: rgba(0, 0, 0, .1);
  border-color: rgba(0, 0, 0, .1)
}

.material-icons {
  font-family: material icons, sans-serif;
  font-weight: var(--website-font-weight-normal);
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: 'liga'
}

ul li {
  list-style: none
}
.personal-info li {
  display: flex;
  gap: 4px;
}
p:last-child {
  margin-bottom: 0
}

.table {
  max-width: 100%;
  margin-bottom: 0;
  width: 100%
}

.table-striped > tbody > tr:nth-of-type(2n+1) {
  background-color: #f8f9fa
}

.table.no-border > tbody > tr > td, .table > tbody > tr > th, .table.no-border > tfoot > tr > td, .table.no-border > tfoot > tr > th, .table.no-border > thead > tr > td, .table.no-border > thead > tr > th {
  padding: 10px 8px
}

.table-nowrap td, .table-nowrap th {
  white-space: nowrap
}

.table.dataTable {
  border-collapse: collapse !important
}

.table-responsive select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative
}

.table-responsive select::after {
  position: absolute;
  content: "#f078"
}

.table td, .table th {
  padding: 15px 10px
}

table.table td h2 {
  display: inline-block;
  font-size: inherit;
  font-weight: var(--website-font-weight-normal);
  margin: 0;
  padding: 0;
  vertical-align: middle
}

a.action_label5 {
  color: #1D5795;
  border: 1px solid #1D5795;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 12px;
  display: block
}

.table-selects.inactive {
  color: #ff8d07;
  border-color: #ff8d07
}

.table-responsive tr td:first-child, .table-responsive tr th:first-child {
  padding-left: 30px !important;
}

.custimze-table table {
  width: 100%
}

.custimze-table th {
  padding: 25px 0;
  border-bottom: 1px solid #ebecf1;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
}

.custimze-table td {
  padding: 15px 0;
  color: #272e48;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
}

.custimze-table {
  border-radius: 5px;
  border: 1px solid #ebecf1
}

.table-selects {
  color: #00882e;
  border: 1px solid #00882e;
  padding: 4px 15px 4px 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: var(--website-font-weight-normal);
}

a.action_label5 svg {
  width: 14px;
  margin-left: 15px
}

.actionset .action_label5 {
  margin-right: 10px !important
}

table.table td h2.table-avatar {
  align-items: center;
  display: inline-flex;
  font-size: inherit;
  font-weight: var(--website-font-weight-normal);
  margin: 0;
  padding: 0;
  vertical-align: middle;
  white-space: nowrap
}

.custimze-table tbody tr td {
  border: 0
}

table.table td h2 a {
  color: #333
}

table.table td h2 a:hover {
  color: #7638ff
}

table.table td h2 span {
  color: #888;
  display: block;
  font-size: 12px;
  margin-top: 3px
}

.table thead {
  background-color: transparent;
  border-bottom: 0
}

.table thead tr th {
  font-weight: var(--website-font-weight-normal);
  font-size: 17px;
  padding-top: 20px;
  padding-bottom: 20px
}

.table.table-center td, .table.table-center th {
  vertical-align: middle
}

.table-hover tbody tr:hover {
  background-color: #f6f6f7
}

.table td, .table th {
  vertical-align: middle;
  white-space: nowrap
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, .05) !important
}

.table-bordered td:first-child {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important
}

.table-bordered td:last-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important
}

.custom-table tbody tr:hover {
  background: #f5f5f5
}

.table-bordered th, .table-bordered td {
  border-color: rgba(0, 0, 0, .05)
}

a.action_label3 {
  font-size: 12px;
  color: #00882e;
  border: 1px solid #00882e;
  padding: 13px 15px;
  border-radius: 5px
}

a.action_label4 {
  font-size: 12px;
  color: #dc3545;
  border: 1px solid #dc3545;
  display: block;
  padding: 7px 15px;
  border-radius: 5px
}

a.action_label4 svg {
  width: 14px;
  margin-left: 35px
}

.card-table .card-body {
  padding: 0
}

.card-table .card-body .table > thead > tr > th {
  border-top: 0
}

.card-table .card-body .table tr td:first-child, .card-table .card-body .table tr th:first-child {
  padding-left: 1.5rem
}

.card-table .card-body .table tr td:last-child, .card-table .card-body .table tr th:last-child {
  padding-right: 1.5rem
}

.card-table .table td, .card-table .table th {
  border-top: 1px solid #e2e5e8;
  padding: 1rem .75rem;
  white-space: nowrap
}

.table tr td label {
  color: #3a3b3f;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  margin: 0
}

.table-select {
  width: 100%;
  color: #00882e;
  border: 1px solid #00882e;
  padding: 10px;
  font-weight: var(--website-font-weight-normal);
  min-width: 115px;
  border-radius: 5px
}

.btn-set {
  padding: 25px 20px 10px
}

.btn-dwnd {
  background: #1D5795;
  color: #fff;
  padding: 15px 45px;
  border: 1px solid #1D5795;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  border-radius: 5px
}

.btn-dwnd:hover {
  background: 0 0;
  color: #1D5795;
}

.table-img img {
  margin-right: 10px
}

.page-name h4 img {
  width: 50px;
  border-radius: 50%
}

.img-table {
  width: 45px;
  height: 45px;
  border: 1px solid #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 50%
}

.employee_grid img {
  width: 100px;
  border: 1px solid #fff;
  border-radius: 50%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
}

.table tr td label.action_label {
  border: 1px solid #1665d8;
  color: #1665d8;
  font-size: 14px;
  padding: 10px 25px;
  border-radius: 5px;
  width: -webkit-fill-available !important;
  text-align: center;
}

.activity-img img {
  width: 36px;
  border-radius: 50%
}

.table tr td label.action_label2 {
  border: 1px solid #ff8d07;
  color: #ff8d07;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  width: 110px;
  text-align: center
}

.table tr td label.action_label.in_active {
  color: #dc3545;
  border: 1px solid #dc3545
}

.block {
  display: block !important
}

.bullets li {
  list-style: inherit
}

#toggle_btn i {
  font-size: 22px
}

.bg-1 {
  background-color: #ffeec3
}

.bg-1 i {
  color: #ffb800
}

.bg-2 {
  background-color: #d1f3ff
}

.bg-2 i {
  color: #42cdff
}

.bg-3 {
  background-color: #ceffd5
}

.bg-3 i {
  color: #52fb6a
}

.bg-4 {
  background-color: #fccaff
}

.bg-4 i {
  color: #f555ff
}

.bg-5 {
  background-color: #ffb800
}

.bg-6 {
  background-color: #42cdff
}

.bg-7 {
  background-color: #52fb6a
}

.bg-8 {
  background-color: #f555ff
}

.table .btn {
  white-space: nowrap
}

table .badge {
  border-radius: 3px;
  display: inline-block;
  font-size: 13px;
  min-width: 75px;
  padding: 4px 12px;
  text-align: center
}

.btn.focus, .btn:focus {
  box-shadow: unset
}

.btn-white {
  background-color: #fff;
  border-color: #e7eaf3
}

.btn.btn-rounded {
  border-radius: 50px
}

.bg-primary, .badge-primary {
  background-color: #1D5795 !important
}

.bg-primary {
  color: #1D5795 !important
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #621aff !important
}

.bg-success, .badge-success {
  background-color: #22cc62 !important
}

.bg-success {
  color: #22cc62 !important
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1eae55 !important
}

.bg-info, .badge-info {
  background-color: #009efb !important
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #028ee1 !important
}

.bg-warning, .badge-warning {
  background-color: #ffbc34 !important
}

.bg-warning {
  color: #ffbc34 !important
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #e9ab2e !important
}

.bg-danger, .badge-danger {
  background-color: #ef3737 !important
}

.bg-secondary {
  color: #898989
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #e63333 !important
}

.bg-white {
  background-color: #fff
}

.bg-purple, .badge-purple {
  background-color: #9368e9 !important
}

.text-primary, .dropdown-menu > li > a.text-primary {
  color: #7638ff !important
}

.text-success, .dropdown-menu > li > a.text-success {
  color: #22cc62 !important
}

.text-danger, .dropdown-menu > li > a.text-danger {
  color: #ef3737 !important
}

.text-info, .dropdown-menu > li > a.text-info {
  color: #009efb !important
}

.text-warning, .dropdown-menu > li > a.text-warning {
  color: #ffbc34 !important
}

.text-purple, .dropdown-menu > li > a.text-purple {
  color: #7460ee !important
}

.text-muted {
  color: #757575 !important
}

.btn-primary {
  background-color: #00882e;
  border: 1px solid #00882e;
  padding: 15px 0
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.active, .btn-primary:active, .open > .dropdown-toggle.btn-primary {
  background-color: #036123;
  border: 1px solid #036123
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary.focus:active, .btn-primary:active:focus, .btn-primary:active:hover, .open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus, .open > .dropdown-toggle.btn-primary:hover {
  background-color: #1D5795;
  border: 1px solid #1D5795;
}

.btn-primary.active:not(:disabled):not(.disabled), .btn-primary:active:not(:disabled):not(.disabled), .show > .btn-primary.dropdown-toggle {
  background-color: #1D5795;
  border-color: #1D5795;
  color: #fff
}

.btn-primary.active:focus:not(:disabled):not(.disabled), .btn-primary:active:focus:not(:disabled):not(.disabled), .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: unset
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #1D5795;
  border-color: #1D5795;
  color: #fff
}

.btn-secondary.active:focus:not(:disabled):not(.disabled), .btn-secondary:active:focus:not(:disabled):not(.disabled), .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: unset
}

.btn-success {
  background-color: #22cc62;
  border: 1px solid #22cc62
}

.btn-success:hover, .btn-success:focus, .btn-success.active, .btn-success:active, .open > .dropdown-toggle.btn-success {
  background-color: #1eae55;
  border: 1px solid #1eae55;
  color: #fff
}

.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success.focus:active, .btn-success:active:focus, .btn-success:active:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
  background-color: #1eae55;
  border: 1px solid #1eae55
}

.btn-success.active:not(:disabled):not(.disabled), .btn-success:active:not(:disabled):not(.disabled), .show > .btn-success.dropdown-toggle {
  background-color: #1eae55;
  border-color: #1eae55;
  color: #fff
}

.btn-success.active:focus:not(:disabled):not(.disabled), .btn-success:active:focus:not(:disabled):not(.disabled), .show > .btn-success.dropdown-toggle:focus {
  box-shadow: unset
}

.btn-success.disabled, .btn-success:disabled {
  background-color: #22cc62;
  border-color: #22cc62;
  color: #fff
}

.btn-info {
  background-color: #009efb;
  border: 1px solid #009efb
}

.btn-info:hover, .btn-info:focus, .btn-info.active, .btn-info:active, .open > .dropdown-toggle.btn-info {
  background-color: #028ee1;
  border: 1px solid #028ee1
}

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info.focus:active, .btn-info:active:focus, .btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
  background-color: #028ee1;
  border: 1px solid #028ee1
}

.btn-info.active:not(:disabled):not(.disabled), .btn-info:active:not(:disabled):not(.disabled), .show > .btn-info.dropdown-toggle {
  background-color: #028ee1;
  border-color: #028ee1;
  color: #fff
}

.btn-info.active:focus:not(:disabled):not(.disabled), .btn-info:active:focus:not(:disabled):not(.disabled), .show > .btn-info.dropdown-toggle:focus {
  box-shadow: unset
}

.btn-info.disabled, .btn-info:disabled {
  background-color: #009efb;
  border-color: #009efb;
  color: #fff
}

.btn-warning {
  background-color: #ffbc34;
  border: 1px solid #ffbc34
}

.btn-warning:hover, .btn-warning:focus, .btn-warning.active, .btn-warning:active, .open > .dropdown-toggle.btn-warning {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e
}

.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning.focus:active, .btn-warning:active:focus, .btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
  background-color: #e9ab2e;
  border: 1px solid #e9ab2e
}

.btn-warning.active:not(:disabled):not(.disabled), .btn-warning:active:not(:disabled):not(.disabled), .show > .btn-danger.dropdown-toggle {
  background-color: #e9ab2e;
  border-color: #e9ab2e;
  color: #fff
}

.btn-warning.active:focus:not(:disabled):not(.disabled), .btn-warning:active:focus:not(:disabled):not(.disabled), .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: unset
}

.btn-warning.disabled, .btn-warning:disabled {
  background-color: #ffbc34;
  border-color: #ffbc34;
  color: #fff
}

.badge-secondary {
  background-color: #621aff !important
}

.btn-danger {
  background-color: #ef3737;
  border: 1px solid #ef3737
}

.btn-danger:hover, .btn-danger:focus, .btn-danger.active, .btn-danger:active, .open > .dropdown-toggle.btn-danger {
  background-color: #ec1313;
  border: 1px solid #ec1313
}

.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger.focus:active, .btn-danger:active:focus, .btn-danger:active:hover, .open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
  background-color: #ec1313;
  border: 1px solid #ec1313
}

.btn-danger.active:not(:disabled):not(.disabled), .btn-danger:active:not(:disabled):not(.disabled), .show > .btn-danger.dropdown-toggle {
  background-color: #ec1313;
  border-color: #ec1313;
  color: #fff
}

.btn-danger.active:focus:not(:disabled):not(.disabled), .btn-danger:active:focus:not(:disabled):not(.disabled), .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: unset
}

.btn-danger.disabled, .btn-danger:disabled {
  background-color: #f62d51;
  border-color: #f62d51;
  color: #fff
}

.btn-light.active:focus:not(:disabled):not(.disabled), .btn-light:active:focus:not(:disabled):not(.disabled), .show > .btn-light.dropdown-toggle:focus {
  box-shadow: unset
}

.btn-dark.active:focus:not(:disabled):not(.disabled), .btn-dark:active:focus:not(:disabled):not(.disabled), .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: unset
}

.btn-outline-primary {
  color: #7638ff;
  border-color: #7638ff
}

.btn-outline-primary:hover {
  background-color: #7638ff;
  border-color: #7638ff
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: none
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #7638ff;
  background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  background-color: #7638ff;
  border-color: #7638ff
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none
}

.btn-outline-success {
  color: #22cc62;
  border-color: #22cc62
}

.btn-outline-success:hover {
  background-color: #22cc62;
  border-color: #22cc62
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: none
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #22cc62;
  background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  background-color: #22cc62;
  border-color: #22cc62
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none
}

.btn-outline-info {
  color: #00bcd4;
  border-color: #00bcd4
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #00bcd4;
  border-color: #00bcd4
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: none
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  background-color: transparent;
  color: #00bcd4
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  background-color: #00bcd4;
  border-color: #00bcd4
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none
}

.btn-outline-warning {
  color: #ffbc34;
  border-color: #ffbc34
}

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: none
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  background-color: transparent;
  color: #ffbc34
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffbc34;
  border-color: #ffbc34
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none
}

.btn-outline-danger {
  color: #ef3737;
  border-color: #ef3737
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ef3737;
  border-color: #ef3737
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: none
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  background-color: transparent;
  color: #ef3737
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  background-color: #ef3737;
  border-color: #ef3737
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none
}

.btn-outline-light {
  color: #ababab;
  border-color: #e6e6e6
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #ababab
}

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #7638ff;
  border-color: #7638ff
}

.pagination > li > a, .pagination > li > span {
  color: #373d3f;
  font-weight: var(--website-font-weight-normal);
}

.page-link:hover {
  color: #7638ff
}

.page-link:focus {
  box-shadow: unset
}

.page-item.active .page-link {
  background-color: #7638ff;
  border-color: #7638ff
}

.dropdown-menu {
  border: 1px solid #eff2f7;
  border-radius: .8rem;
  transform-origin: left top 0;
  background-color: #fff;
  box-shadow: 0 0 1.25rem rgba(31, 45, 61, .08)
}

.navbar-nav .open .dropdown-menu {
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  background-color: #fff
}

.dropdown-menu {
  font-size: 14px
}

.card {
  background: #fff;
  -webkit-box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  margin-bottom: 30px;
  border-radius: 10px;
  border: 0;
}

.card-body {
  position: relative;
  padding: 25px
}

.card .card-header {
  border-color: rgba(137, 137, 137, .25);
  background-color: #fff;
  padding: 25px
}

.card-header:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px
}

.card-footer:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px
}

.card-footer {
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  padding: 1rem
}

.table .thead-light th {
  color: #495057;
  background-color: #f8f9fa;
  border-color: #eff2f7
}

.card .card-header .card-title {
  margin-bottom: 0
}

.modal-footer.text-left {
  text-align: left
}

.modal-footer.text-center {
  text-align: center
}
.text-center {
  color: #747475;
}
.btn-light {
  border-color: #e6e6e6;
  color: #a6a6a6
}

.bootstrap-datetimepicker-widget table td.active, .bootstrap-datetimepicker-widget table td.active:hover {
  background-color: #7638ff;
  text-shadow: unset
}

.bootstrap-datetimepicker-widget table td.today:before {
  border-bottom-color: #7638ff
}

.bg-info-light {
  background-color: rgba(2, 182, 179, .12) !important;
  color: #1db9aa !important
}

.bg-primary-light {
  background-color: rgba(17, 148, 247, .12) !important;
  color: #2196f3 !important
}

.bg-danger-light {
  background-color: rgba(242, 17, 54, .12) !important;
  color: #e63c3c !important
}

.bg-warning-light {
  background-color: rgba(255, 152, 0, .12) !important;
  color: #f39c12 !important
}

.bg-success-light {
  background-color: rgba(15, 183, 107, .12) !important;
  color: #26af48 !important
}

.bg-purple-light {
  background-color: rgba(197, 128, 255, .12) !important;
  color: #c580ff !important
}

.bg-default-light {
  background-color: rgba(40, 52, 71, .12) !important;
  color: #283447 !important
}

.modal {
  -webkit-overflow-scrolling: touch
}

.modal-footer.text-center {
  justify-content: center
}

.modal-footer.text-left {
  justify-content: flex-start
}

.modal-dialog.modal-md {
  max-width: 600px
}

.custom-modal .modal-content {
  border: 0;
  border-radius: 10px
}

.custom-modal .modal-footer {
  border: 0
}

.custom-modal .modal-header {
  border: 0;
  justify-content: center;
  padding: 30px 30px 0
}

.custom-modal .modal-footer {
  border: 0;
  justify-content: center;
  padding: 0 30px 30px
}

.custom-modal .modal-body {
  padding: 30px
}

.custom-modal .close {
  background-color: #a0a0a0;
  border-radius: 50%;
  color: #fff;
  font-size: 13px;
  height: 20px;
  line-height: 20px;
  margin: 0;
  opacity: 1;
  padding: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  z-index: 99
}

.custom-modal .modal-title {
  font-size: 22px
}

.modal-backdrop.show {
  opacity: .4;
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms
}

.customize_popup .modal-header.text-centers {
  padding: 25px;
  align-items: center;
  justify-content: center
}

.modal .card {
  box-shadow: unset
}

.customize_popup .modal-header.text-centers h5 {
  font-size: 18px
}

.customize_popup .text-centers.modal-footer button {
  width: auto;
  padding: 10px 18px !important
}

.customize_popup .modal-footer.text-centers {
  justify-content: center
}

.select2-container .select2-selection--single {
  border: 1px solid #ddd;
  height: 40px
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 38px;
  right: 7px
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #ddd transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 5px
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #ddd;
  border-width: 0 6px 6px
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-right: 20px;
  padding-left: 30px
}

.select-path .select2-container .select2-selection--single {
  border: 1px solid #ddd;
  height: 60px
}

.select-path .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #333;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  line-height: 58px
}

.select-path .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 58px;
  right: 7px
}

.tab-select .select2-container .select2-selection--single {
  border: 1px solid #00882e !important;
  height: 40px
}

.tab-select .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #00882e !important;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  line-height: 38px
}

.tab-select .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: 10px;
  width: 25px;
  background: url(assets/img/down-arrow-green.png) no-repeat 0 0/14px 7px;
  height: 15px
}

.tab-select .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: 10px;
  width: 25px;
  background: url(assets/img/down-arrow-green.png) no-repeat 0 0/14px 7px;
  height: 15px
}

.tab-select .select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  min-width: 160px;
  width: 160px !important
}

.tab-select.inactive .select2-container .select2-selection--single {
  border: 1px solid #ff8d07 !important;
  height: 40px
}

.tab-select.inactive .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #ff8d07 !important;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  line-height: 38px
}

.tab-select.inactive .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #ff8d07 transparent transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  height: 0;
  left: 50%;
  margin-left: -10px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0
}

.form-group .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 70px;
  right: 25px;
  top: 20px
}

.form-group .select2-container .select2-selection--single {
  border: 1px solid #ddd;
  height: 70px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #00882e;
  border-width: 0 6px 6px
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #000;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  line-height: 38px
}

.tab-select.inactive .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: 10px;
  width: 25px;
  background: url(assets/img/arrow-down-orange.png) no-repeat 0 0/14px 7px;
  height: 15px
}

.tab-select.inactive .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: 10px;
  width: 25px;
  background: url(assets/img/arrow-down-orange.png) no-repeat 0 0/14px 7px;
  height: 15px
}

.tab-select.inactive .select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #ff8d07 !important
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #00882e
}

.select2-container--default .select2-selection--multiple {
  border: 1px solid #ddd;
  min-height: 40px
}

.form-group .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: 10px;
  width: 25px;
  background: url(assets/img/dropdown.png) no-repeat 0 0/14px 7px;
  height: 15px
}

.form-group .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent;
  border-style: solid;
  border-width: 6px 6px 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  position: absolute;
  top: 10px;
  width: 25px;
  background: url(assets/img/dropdown.png) no-repeat 0 0/14px 7px;
  height: 15px
}

.nav-tabs {
  border-bottom: 1px solid #e6e6e6
}

.card-header-tabs {
  border-bottom: 0
}

.nav-tabs > li > a {
  margin-right: 0;
  color: #888;
  border-radius: 0
}

.nav-tabs > li > a:hover, .nav-tabs > li > a:focus {
  border-color: transparent;
  color: #333
}

.nav-tabs.nav-tabs-solid > li > a {
  color: #333
}

.nav-tabs.nav-tabs-solid > .active > a, .nav-tabs.nav-tabs-solid > .active > a:hover, .nav-tabs.nav-tabs-solid > .active > a:focus {
  background-color: #7638ff;
  border-color: #7638ff;
  color: #fff
}

.nav-tabs .nav-link {
  border-radius: 0
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  background-color: #eee;
  border-color: transparent;
  color: #333
}

.head-link-set .nav-tabs .nav-item.show .nav-link, .head-link-set .nav-tabs .nav-link.active {
  color: #fff;
  background-color: #1D5795;
  border-color: transparent
}

.nav-tabs.nav-justified > li > a {
  border-radius: 0;
  margin-bottom: 0
}

.nav-tabs.nav-justified > li > a:hover, .nav-tabs.nav-justified > li > a:focus {
  border-bottom-color: #ddd
}

.nav-tabs.nav-justified.nav-tabs-solid > li > a {
  border-color: transparent
}

.nav-tabs.nav-tabs-solid > li > a {
  color: #333;
  padding: 10px 30px
}

.nav-tabs.nav-tabs-solid > li > a.active, .nav-tabs.nav-tabs-solid > li > a.active:hover, .nav-tabs.nav-tabs-solid > li > a.active:focus {
  background-color: #7638ff;
  border-color: #7638ff;
  color: #fff;
  padding: 10px 30px
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded {
  border-radius: 50px
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a {
  border-radius: 50px
}

.nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active, .nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:hover, .nav-tabs.nav-tabs-solid.nav-tabs-rounded > li > a.active:focus {
  border-radius: 50px
}

.nav-tabs-justified > li > a {
  border-radius: 0;
  margin-bottom: 0
}

.nav-tabs-justified > li > a:hover, .nav-tabs-justified > li > a:focus {
  border-bottom-color: #ddd
}

.nav-tabs-justified.nav-tabs-solid > li > a {
  border-color: transparent
}

.nav-tabs.nav-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd
}

.nav-tabs.nav-justified.nav-tabs-top > li > a, .nav-tabs.nav-justified.nav-tabs-top > li > a:hover, .nav-tabs.nav-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0
}

.nav-tabs.nav-tabs-top > li {
  margin-bottom: 0
}

.nav-tabs.nav-tabs-top > li > a, .nav-tabs.nav-tabs-top > li > a:hover, .nav-tabs.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0
}

.nav-tabs.nav-tabs-top > li.open > a, .nav-tabs.nav-tabs-top > li > a:hover, .nav-tabs.nav-tabs-top > li > a:focus {
  border-top-color: #ddd
}

.nav-tabs.nav-tabs-top > li + li > a {
  margin-left: 1px
}

.nav-tabs.nav-tabs-top > li > a.active, .nav-tabs.nav-tabs-top > li > a.active:hover, .nav-tabs.nav-tabs-top > li > a.active:focus {
  border-top-color: #7638ff
}

.nav-tabs.nav-tabs-bottom > li {
  margin-bottom: -1px
}

.nav-tabs.nav-tabs-bottom > li > a.active, .nav-tabs.nav-tabs-bottom > li > a.active:hover, .nav-tabs.nav-tabs-bottom > li > a.active:focus {
  border-bottom-width: 2px;
  border-color: transparent;
  border-bottom-color: #7638ff;
  background-color: transparent;
  transition: none 0s ease 0s;
  -moz-transition: none 0s ease 0s;
  -o-transition: none 0s ease 0s;
  -transition: none 0s ease 0s;
  -webkit-transition: none 0s ease 0s
}

.nav-tabs.nav-tabs-solid {
  background-color: #fafafa;
  border: 0
}

.nav-tabs.nav-tabs-solid > li {
  margin-bottom: 0
}

.nav-tabs.nav-tabs-solid > li > a {
  border-color: transparent
}

.nav-tabs.nav-tabs-solid > li > a:hover, .nav-tabs.nav-tabs-solid > li > a:focus {
  background-color: #f5f5f5
}

.nav-tabs.nav-tabs-solid > .open:not(.active) > a {
  background-color: #f5f5f5;
  border-color: transparent
}

.nav-tabs-justified.nav-tabs-top {
  border-bottom: 1px solid #ddd
}

.nav-tabs-justified.nav-tabs-top > li > a, .nav-tabs-justified.nav-tabs-top > li > a:hover, .nav-tabs-justified.nav-tabs-top > li > a:focus {
  border-width: 2px 0 0
}

.section-header {
  margin-bottom: 1.875rem
}

.section-header .section-title {
  color: #333
}

.line {
  background-color: #7638ff;
  height: 2px;
  margin: 0;
  width: 60px
}

.comp-buttons .btn {
  margin-bottom: 5px
}

.pagination-box .pagination {
  margin-top: 0
}

.comp-dropdowns .btn-group {
  margin-bottom: 5px
}

.progress-example .progress {
  margin-bottom: 1.5rem
}

.progress-xs {
  height: 4px
}

.progress-sm {
  height: 15px
}

.progress.progress-sm {
  height: 6px
}

.progress.progress-md {
  height: 8px
}

.progress.progress-lg {
  height: 18px
}

.row.row-sm {
  margin-left: -3px;
  margin-right: -3px
}

.row.row-sm > div {
  padding-left: 3px;
  padding-right: 3px
}

.avatar {
  position: relative;
  display: inline-block
}

.avatar-title {
  width: 100%;
  height: 100%;
  background-color: #7638ff;
  color: #fff;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center
}

.avatar-away:before, .avatar-offline:before, .avatar-online:before {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 25%;
  height: 25%;
  border-radius: 50%;
  content: '';
  border: 2px solid #fff
}

.avatar-online:before {
  background-color: #22cc62
}

.avatar-offline:before {
  background-color: #ef3737
}

.avatar-away:before {
  background-color: #ffbc34
}

.employee-image .avatar > img {
  width: 50px;
  border: 1px solid #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  -o-object-fit: cover;
  object-fit: cover;
  height: 50px;
  border-radius: 50%;
  margin-left: 1px;
}

.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.member-formcontent .avatar > img {
  width: 70px;
  height: 70px;
  border: 1px solid #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25)
}

.avatar .border {
  border-width: 3px !important
}

.avatar .rounded {
  border-radius: 6px !important
}

.avatar .avatar-title {
  font-size: 18px
}

.avatar-xs {
  width: 41px
}

.avatar-xs .border {
  border-width: 2px !important
}

.avatar-xs .rounded {
  border-radius: 4px !important
}

.avatar-xs .avatar-title {
  font-size: 12px
}

.avatar-xs.avatar-away:before, .avatar-xs.avatar-offline:before, .avatar-xs.avatar-online:before {
  border-width: 1px
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem
}

.avatar-sm .border {
  border-width: 3px !important
}

.avatar-sm .rounded {
  border-radius: 4px !important
}

.avatar-sm .avatar-title {
  font-size: 15px
}

.avatar-sm.avatar-away:before, .avatar-sm.avatar-offline:before, .avatar-sm.avatar-online:before {
  border-width: 2px
}

.avatar-lg {
  width: 3.75rem;
  height: 3.75rem
}

.avatar-lg .border {
  border-width: 3px !important
}

.avatar-lg .rounded {
  border-radius: 8px !important
}

.avatar-lg .avatar-title {
  font-size: 24px
}

.avatar-lg.avatar-away:before, .avatar-lg.avatar-offline:before, .avatar-lg.avatar-online:before {
  border-width: 3px
}

.avatar-xl {
  width: 5rem;
  height: 5rem
}

.avatar-xl .border {
  border-width: 4px !important
}

.avatar-xl .rounded {
  border-radius: 8px !important
}

.avatar-xl .avatar-title {
  font-size: 28px
}

.avatar-xl.avatar-away:before, .avatar-xl.avatar-offline:before, .avatar-xl.avatar-online:before {
  border-width: 4px
}

.avatar-xxl {
  width: 5.125rem;
  height: 5.125rem
}

.avatar-xxl .border {
  border-width: 6px !important
}

.avatar-xxl .rounded {
  border-radius: 8px !important
}

.avatar-xxl .avatar-title {
  font-size: 30px
}

.avatar-xxl.avatar-away:before, .avatar-xxl.avatar-offline:before, .avatar-xxl.avatar-online:before {
  border-width: 4px
}

.avatar-group {
  display: inline-flex
}

.avatar-group .avatar + .avatar {
  margin-left: -.75rem
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left:2.59375rem
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -.625rem
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem
}

.avatar-group .avatar:hover {
  z-index: 1
}

.employee-name{
  z-index: 2;
  position: absolute;
  color: #747475;
  margin-top: 54px;
  font: 400 calc(14px* 0.83) / 20px Roboto, sans-serif;
  text-align: center;
  line-height: 14.5px;
}

.avatar-group .avatar:hover .employee-name {
  display: block;
}

.header {
  background: #fff;
  box-shadow: 0 6px 16px 0 rgb(137 137 137/25%);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  height: 70px;
  padding: 5px 0;
  z-index:2;
}

.header .header-left {
  float: left;
  height: 60px;
  padding: 0 20px;
  position: relative;
  text-align: left;
  width: 270px;
  z-index: 1;
  transition: all .2s ease-in-out;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between
}

.header .header-left .logo {
  display: inline-block;
  line-height: 60px
}

.header .header-left .logo img {
  max-height: 45px;
  width: auto
}

.header-left .logo.logo-small {
  display: none
}

.header .dropdown-menu > li > a {
  position: relative
}

.header .dropdown-toggle:after {
  display: none
}

.mini-sidebar .sidebar-bottom > .logout > li > a {
  display: inline-block;
  padding: 0
}

.mini-sidebar.expand-menu .sidebar-bottom > .logout > li > a {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  padding: 12px 15px
}

.sidebar-bottom > .logout > li > a {
  justify-content: center
}

.sidebar .sidebar-bottom > .logout > li > a span {
  margin-left: 5px
}

.header .has-arrow .dropdown-toggle:after {
  border-top: 0;
  border-left: 0;
  border-bottom: transparent;
  border-right: transparent;
  content: '';
  height: 8px;
  display: inline-block;
  pointer-events: none;
  -webkit-transform-origin: 66% 66%;
  -transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all .15s ease-in-out;
  transition: all .15s ease-in-out;
  width: 8px;
  vertical-align: 2px
}

.header .has-arrow .dropdown-toggle[aria-expanded=true]:after {
  -webkit-transform: rotate(-135deg);
  -transform: rotate(-135deg);
  transform: rotate(-135deg)
}

.user-menu {
  float: right;
  margin: 0;
  position: relative;
  z-index: 99;
  display: flex !important;
  padding-right: 20px
}

.user-menu.nav > li > a {
  color: #000;
  font-size: 14px;
  line-height: 58px;
  padding: 0 1rem;
  height: 60px;
  font-weight: var(--website-font-weight-normal);
}

.user-menu.nav > li > a:hover i, .user-menu.nav > li > a:focus i {
  opacity: .8
}

.user-img {
  display: inline-block;
  margin-right: 3px;
  position: relative
}

.user-img img {
  width: 48px;
  height: 48px; /* Set the height to match the width */
  border-radius: 50%;
  object-fit: cover; /* Maintain aspect ratio and cover the entire circular container */
}

.dropdown-item img {
  margin-right: 5px
}

.user-menu.nav > li > a.mobile_btn {
  border: 0;
  position: relative;
  padding: 0;
  margin: 0;
  cursor: pointer
}

.user-menu .dropdown-menu {
  min-width: 200px;
  padding: 0
}

.user-menu .dropdown-menu .dropdown-item {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  padding: 10px 15px
}

.user-menu .dropdown-menu .dropdown-item:hover {
  background-color: transparent
}

.header .dropdown-menu > li > a:focus, .header .dropdown-menu > li > a:hover {
  background-color: #1D5795;
  color: #fff
}

.header .dropdown-menu > li > a:focus i, .header .dropdown-menu > li > a:hover i {
  color: #fff
}

.header .dropdown-menu > li > a {
  padding: 10px 18px
}

.header .dropdown-menu > li > a i {
  color: #1D5795;
  margin-right: 10px;
  text-align: center;
  width: 18px
}

.header .user-menu .dropdown-menu > li > a i {
  color: #1D5795;
  font-size: 16px;
  margin-right: 10px;
  min-width: 18px;
  text-align: center
}

.header .user-menu .dropdown-menu > li > a:focus i, .header .user-menu .dropdown-menu > li > a:hover i {
  color: #fff
}

.mobile_btn {
  display: none;
  float: left
}

.slide-nav .sidebar {
  margin-left: 0
}

.menu-title {
  color: #a3a3a3;
  display: block;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 0 25px
}

.sidebar-overlay {
  background-color: rgba(0, 0, 0, .6);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000
}

.sidebar-overlay.opened {
  display: block
}

html.menu-opened {
  overflow: hidden
}

html.menu-opened body {
  overflow: hidden
}

.chat-window {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 1.875rem;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
  border-radius: 20px;
  overflow: hidden
}

.chat-window .chat-cont-left {
  border-right: 1px solid #e5e5e5;
  flex: 0 0 35%;
  left: 0;
  max-width: 35%;
  position: relative;
  z-index: 4
}

.chat-window .chat-cont-left .chat-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  color: #324148;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px
}

.chat-window .chat-cont-left .chat-header span {
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  text-transform: capitalize
}

.chat-window .chat-cont-left .chat-header .chat-compose {
  color: #8a8a8a;
  display: inline-flex
}

.chat-window .chat-cont-left .chat-search {
  background-color: #f5f5f6;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  width: 100%
}

.chat-window .chat-cont-left .chat-search .input-group {
  width: 100%
}

.chat-window .chat-cont-left .chat-search .input-group .form-control {
  background-color: #fff;
  border-radius: 50px;
  padding-left: 36px
}

.chat-window .chat-cont-left .chat-search .input-group .form-control:focus {
  border-color: #ccc;
  box-shadow: none
}

.chat-window .chat-cont-left .chat-search .input-group .input-group-prepend {
  align-items: center;
  bottom: 0;
  color: #666;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 4
}

.chat-window .chat-scroll {
  max-height: calc(100vh - 255px);
  overflow-y: auto
}

.chat-window .chat-cont-left .chat-users-list {
  background-color: #fff
}

.chat-window .chat-cont-left .chat-users-list a.media {
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 15px;
  transition: all .2s ease 0s
}

.chat-window .chat-cont-left .chat-users-list a.media:last-child {
  border-bottom: 0
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap {
  margin-right: 15px;
  position: relative
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .avatar {
  height: 45px;
  width: 45px
}

.chat-window .chat-cont-left .chat-users-list a.media .media-img-wrap .status {
  bottom: 7px;
  height: 10px;
  right: 4px;
  position: absolute;
  width: 10px;
  border: 2px solid #fff
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, .chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name {
  color: #333;
  text-transform: capitalize
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
  color: #8a8a8a;
  font-size: 14px;
  line-height: 24px
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:last-child {
  text-align: right
}

.chat-window .chat-cont-left .chat-users-list a.media .media-body > div:last-child .last-chat-time {
  color: #8a8a8a;
  font-size: 13px
}

.chat-window .chat-cont-left .chat-users-list a.media:hover {
  background-color: #f5f5f6
}

.chat-window .chat-cont-left .chat-users-list a.media.read-chat .media-body > div:last-child .last-chat-time {
  color: #8a8a8a
}

.chat-window .chat-cont-left .chat-users-list a.media.active {
  background-color: #f5f5f6
}

.chat-window .chat-cont-right {
  flex: 0 0 65%;
  max-width: 65%
}

.chat-window .chat-cont-right .chat-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 15px
}

.chat-window .chat-cont-right .chat-header .back-user-list {
  display: none;
  margin-right: 5px;
  margin-left: -7px
}

.chat-window .chat-cont-right .chat-header .media {
  align-items: center
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap {
  position: relative;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  margin-right: 15px
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .avatar {
  height: 50px;
  width: 50px
}

.chat-window .chat-cont-right .chat-header .media .media-img-wrap .status {
  border: 2px solid #fff;
  bottom: 0;
  height: 10px;
  position: absolute;
  right: 3px;
  width: 10px
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-name {
  color: #333;
  font-size: 16px;
  font-weight: var(--website-font-weight-normal);
  text-transform: capitalize
}

.chat-window .chat-cont-right .chat-header .media .media-body .user-status {
  color: #666;
  font-size: 14px
}

.chat-window .chat-cont-right .chat-header .chat-options {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex
}

.chat-window .chat-cont-right .chat-header .chat-options > a {
  align-items: center;
  border-radius: 50%;
  color: #8a8a8a;
  display: inline-flex;
  height: 30px;
  justify-content: center;
  margin-left: 10px;
  width: 30px
}

.chat-window .chat-cont-right .chat-body {
  background-color: #f5f5f6
}

.chat-window .chat-cont-right .chat-body ul.list-unstyled {
  margin: 0 auto;
  padding: 15px;
  width: 100%
}

.chat-window .chat-cont-right .chat-body .media .avatar {
  height: 30px;
  width: 30px
}

.chat-window .chat-cont-right .chat-body .media .media-body {
  margin-left: 20px
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div {
  padding: 10px 15px;
  border-radius: .25rem;
  display: inline-block;
  position: relative
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box > div p {
  color: #333;
  margin-bottom: 0
}

.chat-window .chat-cont-right .chat-body .media .media-body .msg-box + .msg-box {
  margin-top: 5px
}

.chat-window .chat-cont-right .chat-body .media.received {
  margin-bottom: 20px
}

.chat-window .chat-cont-right .chat-body .media:last-child {
  margin-bottom: 0
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box > div {
  background-color: #fff
}

.chat-window .chat-cont-right .chat-body .media.sent {
  margin-bottom: 20px
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body {
  align-items: flex-end;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 0
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div {
  background-color: #e3e3e3
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div p {
  color: #333
}

.chat-window .chat-cont-right .chat-body .chat-date {
  font-size: 14px;
  margin: 1.875rem 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-transform: capitalize
}

.chat-window .chat-cont-right .chat-body .chat-date:before {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  margin-right: 28px;
  position: absolute;
  right: 50%;
  top: 50%;
  width: 100%
}

.chat-window .chat-cont-right .chat-body .chat-date:after {
  background-color: #e0e3e4;
  content: "";
  height: 1px;
  left: 50%;
  margin-left: 28px;
  position: absolute;
  top: 50%;
  width: 100%
}

.chat-window .chat-cont-right .chat-footer {
  background-color: #fff;
  border-top: 1px solid #e0e3e4;
  padding: 10px 15px;
  position: relative
}

.chat-window .chat-cont-right .chat-footer .input-group {
  width: 100%
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control {
  background-color: #f5f5f6;
  border: none;
  border-radius: 50px
}

.chat-window .chat-cont-right .chat-footer .input-group .form-control:focus {
  background-color: #f5f5f6;
  border: none;
  box-shadow: none
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-prepend .btn, .chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn {
  background-color: transparent;
  border: none;
  color: #9f9f9f
}

.chat-window .chat-cont-right .chat-footer .input-group .input-group-append .btn.msg-send-btn {
  background-color: #7638ff;
  border-color: #7638ff;
  border-radius: 50%;
  color: #fff;
  margin-left: 10px
}

.msg-typing {
  width: auto;
  height: 24px;
  padding-top: 8px
}

.msg-typing span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #a0a0a0;
  display: block;
  border-radius: 50%;
  opacity: .4
}

.msg-typing span:nth-of-type(1) {
  animation: 1s blink infinite .33333s
}

.msg-typing span:nth-of-type(2) {
  animation: 1s blink infinite .66666s
}

.msg-typing span:nth-of-type(3) {
  animation: 1s blink infinite .99999s
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box {
  position: relative
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid #fff;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  left: -6px;
  position: absolute;
  right: auto;
  top: 8px;
  width: 0
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box {
  padding-left: 50px;
  position: relative
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box:first-child:before {
  border-bottom: 6px solid transparent;
  border-left: 6px solid #e3e3e3;
  border-top: 6px solid transparent;
  content: "";
  height: 0;
  left: auto;
  position: absolute;
  right: -6px;
  top: 8px;
  width: 0
}

.chat-msg-info {
  align-items: center;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  clear: both;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 5px 0 0
}

.chat-msg-info li {
  font-size: 13px;
  padding-right: 16px;
  position: relative
}

.chat-msg-info li:not(:last-child):after {
  position: absolute;
  right: 8px;
  top: 50%;
  content: '';
  height: 4px;
  width: 4px;
  background: #d2dde9;
  border-radius: 50%;
  transform: translate(50%, -50%)
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box .chat-msg-info li:not(:last-child)::after {
  right: auto;
  left: 8px;
  transform: translate(-50%, -50%);
  background: #aaa
}

.chat-window .chat-cont-right .chat-body .media.received .media-body .msg-box > div .chat-time {
  color: rgba(50, 65, 72, .4)
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-time {
  color: rgba(50, 65, 72, .4)
}

.chat-msg-info li a {
  color: #777
}

.chat-msg-info li a:hover {
  color: #2c80ff
}

.chat-seen i {
  color: #00d285;
  font-size: 16px
}

.chat-msg-attachments {
  padding: 4px 0;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  width: 100%;
  margin: 0 -1px
}

.chat-msg-attachments > div {
  margin: 0 1px
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info {
  flex-direction: row-reverse
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-attachments {
  flex-direction: row-reverse
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div .chat-msg-info li {
  padding-left: 16px;
  padding-right: 0;
  position: relative
}

.chat-attachment img {
  max-width: 100%
}

.chat-attachment {
  position: relative;
  max-width: 130px;
  overflow: hidden
}

.chat-attachment {
  border-radius: .25rem
}

.chat-attachment:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  content: "";
  opacity: .4;
  transition: all .4s
}

.chat-attachment:hover:before {
  opacity: .6
}

.chat-attach-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 7px 15px;
  font-size: 13px;
  opacity: 1;
  transition: all .4s
}

.chat-attach-download {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .4s;
  color: #fff;
  width: 32px;
  line-height: 32px;
  background: rgba(255, 255, 255, .2);
  text-align: center
}

.chat-attach-download:hover {
  color: #495463;
  background: #fff
}

.chat-attachment:hover .chat-attach-caption {
  opacity: 0
}

.chat-attachment:hover .chat-attach-download {
  opacity: 1
}

.chat-attachment-list {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  margin: -5px
}

.chat-attachment-list li {
  width: 33.33%;
  padding: 5px
}

.chat-attachment-item {
  border: 5px solid rgba(230, 239, 251, .5);
  height: 100%;
  min-height: 60px;
  text-align: center;
  font-size: 30px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center
}

.chat-window .chat-cont-right .chat-body .media.sent .media-body .msg-box > div:hover .chat-msg-actions {
  opacity: 1
}

.chat-msg-actions {
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all .4s;
  z-index: 2
}

.chat-msg-actions > a {
  padding: 0 10px;
  color: #495463;
  font-size: 24px
}

.chat-msg-actions > a:hover {
  color: #2c80ff
}

@keyframes blink {
  50% {
    opacity: 1
  }
}

.btn-file {
  align-items: center;
  display: inline-flex;
  font-size: 20px;
  justify-content: center;
  overflow: hidden;
  padding: 0 .75rem;
  position: relative;
  vertical-align: middle
}

.btn-file input {
  cursor: pointer;
  filter: alpha(opacity=0);
  font-size: 23px;
  height: 100%;
  margin: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%
}

.top-nav-search {
  float: left;
  margin-left: 15px;
  width: 40%
}

.top-nav-search form {
  margin-top: 10px;
  position: relative;
  width: 100%
}

.top-nav-search .form-control {
  border: 1px solid rgba(137, 137, 137, .25);
  border-radius: 50px;
  color: #333;
  height: 40px;
  padding: 10px 15px 10px 50px;
  box-shadow: rgb(209 243 255/33%) 0 .2px 3px .1px
}

.top-nav-search .btn {
  background-color: transparent;
  border-color: transparent;
  color: #333;
  min-height: 40px;
  padding: 7px 15px;
  position: absolute;
  left: 0;
  top: 0
}

.top-nav-search.active form {
  display: block;
  left: 0;
  position: absolute
}

.top-nav-search i {
  color: #898989;
  font-size: 14px
}

.sidebar {
  bottom: 0;
  left: 0;
  margin-top: 0;
  position: fixed;
  top: 70px;
  transition: all .2s ease-in-out 0s;
  width: 270px;
  z-index: 9;
  overflow-y: hidden;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%)
}

.sidebar.opened {
  -webkit-transition: all .4s ease;
  -moz-transition: all .4s ease;
  transition: all .4s ease
}

.sidebar-inner {
  height: 100%;
  min-height: 100%;
  transition: all .2s ease-in-out 0s
}

.sidebar-menu ul {
  font-size: 15px;
  list-style-type: none;
  margin: 0;
  padding: 25px 0 95px;
  position: relative
}

.member-row {
  border: 1px solid #ebecf1;
  padding: 25px;
  border-radius: 5px
}

.sidebar-menu li a {
  color: #555a64;
  display: block;
  font-size: 15px;
  height: auto;
  padding: 0 20px
}

.sidebar-menu li a:hover {
  color: #7638ff
}

.sidebar-menu li.active > a {
  color: #1D5795;
  position: relative;
  background: #f2f3f9
}

.menu-title {
  color: #9e9e9e;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  font-size: 14px;
  opacity: 1;
  padding: 5px 15px;
  white-space: nowrap
}

.menu-title > i {
  float: right;
  line-height: 40px
}

.mobile-show {
  display: none
}

.sidebar-menu li.menu-title a {
  color: #ff9b44;
  display: inline-block;
  margin-left: auto;
  padding: 0
}

.sidebar-menu li.menu-title a.btn {
  color: #fff;
  display: block;
  float: none;
  font-size: 15px;
  margin-bottom: 15px;
  padding: 10px 15px
}

.sidebar-menu ul ul a.active {
  color: #7638ff
}

.mobile_btn {
  display: none;
  float: left
}

.sidebar .sidebar-menu > ul > li > a span {
  transition: all .2s ease-in-out 0s;
  display: inline-block;
  margin-left: 25px;
  white-space: nowrap;
  font-weight: var(--website-font-weight-normal);
}

.sidebar .sidebar-menu > ul > li > a span.chat-user {
  margin-left: 0;
  overflow: hidden;
  text-overflow: ellipsis
}

.sidebar .sidebar-menu > ul > li > a span.badge {
  margin-left: auto
}

.sidebar-menu ul ul a {
  display: block;
  font-size: 15px;
  padding: 7px 10px 7px 45px;
  position: relative
}

.sidebar-menu ul ul a span {
  float: right;
  color: #ff9800
}

.sidebar-menu ul ul {
  display: none
}

.sidebar-menu ul ul ul a {
  padding-left: 65px
}

.sidebar-menu ul ul ul ul a {
  padding-left: 85px
}

.sidebar-menu > ul > li {
  margin-bottom: 3px;
  position: relative
}

.sidebar-menu .menu-arrow {
  -webkit-transition: -webkit-transform .15s;
  -o-transition: -o-transform .15s;
  transition: transform .15s;
  position: absolute;
  right: 15px;
  display: inline-block;
  font-family: "font awesome 5 free";
  font-weight: var(--website-font-weight-normal);
  text-rendering: auto;
  line-height: 40px;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0, 0);
  -transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  line-height: 18px;
  top: 11px
}

.logout a {
  background: #cbd1ee;
  border-radius: 10px
}

.sidebar-bottom ul.logout {
  padding: 15px
}

.sidebar-menu .menu-arrow:before {
  content: "\f105"
}

.sidebar-menu li a.subdrop .menu-arrow {
  -transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg)
}

.sidebar-menu ul ul a .menu-arrow {
  top: 10px
}

.sidebar-menu > ul > li > a {
  align-items: center;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: flex-start;
  padding: 12px 25px;
  position: relative;
  transition: all .2s ease-in-out 0s
}

.sidebar-menu > ul > li > a:hover {
  background: #f2f3f9
}

.sidebar-menu > ul > li > a svg {
  width: 25px;
  background: #cbd1ee;
  padding: 3px;
  height: 25px;
  border-radius: 5px
}

.bg-theme {
  background-color: #1D5795
}

.sidebar-menu > ul > li > a img {
  width: 25px;
  background: #cbd1ee;
  padding: 5px;
  height: 25px;
  border-radius: 5px
}

.user-info a.menu-style {
  padding: 30px 0;
  padding-right: 0
}

.offcanvas-menu .lnr-cross {
  position: absolute;
  right: 20px;
  top: 10px
}

.top-nav-search {
  margin-right: 15px;
  margin-left: 15px;
  transition: all .4s ease 0s
}

.sidebar-menu .user-avatar img.rounded-circle {
  width: 100px;
  border: 1px solid #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25)
}

.sidebar-menu ul li a i {
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  vertical-align: middle;
  width: 20px;
  transition: all .2s ease-in-out 0s
}

.sidebar-menu ul li.menu-title a i {
  font-size: 16px !important;
  margin-right: 0;
  text-align: right;
  width: auto
}

.sidebar-menu li a > .badge {
  color: #fff
}

[data-toggle=tooltip] {
  position: relative;
  display: inline-block;
  z-index: 999
}

[data-toggle=tooltip]::before {
  content: "";
  position: absolute;
  top: -6px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px 6px 0;
  border-style: solid;
  border-color: #181919 transparent transparent transparent;
  z-index: 99;
  opacity: 0
}

[data-placement=left]::before {
  left: 0%;
  top: 50%;
  margin-left: -12px;
  transform: translateY(-50%) rotate(-90deg)
}

[data-placement=top]::before {
  left: 50%
}

[data-placement=bottom]::before {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translatey(-100%) rotate(-180deg)
}

[data-placement=right]::before {
  left: 100%;
  top: 50%;
  margin-left: 1px;
  transform: translateY(-50%) rotate(90deg);
  transition: all .5s
}

[data-toggle=tooltip]::after {
  content: attr(tooltip-title);
  position: absolute;
  left: 50%;
  top: -6px;
  transform: translateX(-50%) translateY(-100%);
  background: #420ba1;
  text-align: center;
  color: #fff;
  font-size: 12px;
  min-width: 100px;
  border-radius: 5px;
  pointer-events: none;
  padding: 5px;
  z-index: 99;
  opacity: 0;
  transition: all .5s
}

[data-placement=left]::after {
  left: 0%;
  top: 50%;
  margin-left: -7px;
  transform: translateX(-100%) translateY(-50%)
}

[data-placement=top]::after {
  left: 50%
}

[data-placement=bottom]::after {
  top: 100%;
  margin-top: 8px;
  transform: translateX(-50%) translateY(0%)
}

[data-placement=right]::after {
  left: 100%;
  top: 50%;
  margin-left: 8px;
  transform: translateX(0%) translateY(-50%)
}

[data-toggle=tooltip]:hover::after, [data-toggle=tooltip]:hover::before {
  opacity: 1
}

.calendar-events {
  border: 1px solid #ebecf1;
  cursor: move;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: var(--website-font-weight-normal);
}

.calendar_events {
  padding: 15px
}

.calendar_events h4.card-title {
  font-size: 12px
}

.calendar-events:hover {
  border-color: #e9e9e9;
  background-color: #fff
}

.calendar-events i {
  margin-right: 8px
}

.calendar {
  float: left;
  margin-bottom: 0
}

.fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5rem
}

.none-border .modal-footer {
  border-top: none
}

.calendar_page .card-body {
  padding: 0;
  border-radius: 10px
}

.calendar_page .card-body {
  padding: 0;
  border-radius: 10px
}

.calendar_page .fc-toolbar.fc-header-toolbar {
  padding: 35px 25px;
  border-bottom: 1px solid #d5d5d5
}

h2.calendar_head {
  padding: 15px;
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  border-bottom: 1px solid rgba(137, 137, 137, .25)
}

.calendar_page .card {
  border-radius: 10px
}

.calendar_page .fc-view-container {
  padding: 0 20px
}

.calendar_page .fc-state-default {
  box-shadow: 0 1px 10px 0 rgb(137 137 137/25%) !important;
  background: 0 0
}

.calendar_page button.fc-next-button.fc-button.fc-state-default.fc-corner-right {
  border-radius: 0 10px 10px 0 !important
}

.calendar_page button.fc-prev-button.fc-button.fc-state-default.fc-corner-left {
  border-radius: 10px 0 0 10px !important
}

.calendar_page .fc-state-default {
  box-shadow: 0 1px 10px 0 rgb(137 137 137/25%) !important;
  background-color: transparent !important;
  color: #000 !important;
  font-weight: var(--website-font-weight-normal);
  border-radius: 5px !important
}

.calendar_page .fc-toolbar.fc-header-toolbar {
  padding: 35px 25px;
  border-bottom: 1px solid #d5d5d5
}

.calendar_page .card {
  border-radius: 10px
}

.calendar_page .fc-view-container {
  padding: 0 20px
}

.fc-toolbar h2 {
  font-size: 18px;
  font-weight: var(--website-font-weight-normal);
  font-family: var(--website-font-family);
  line-height: 30px;
  text-transform: uppercase
}

.fc-day-grid-event .fc-time {
  font-family: roboto, sans-serif
}

.fc-day {
  background: #fff
}

.fc-toolbar .fc-state-active, .fc-toolbar .ui-state-active, .fc-toolbar button:focus, .fc-toolbar button:hover, .fc-toolbar .ui-state-hover {
  z-index: 0
}

.fc th.fc-widget-header {
  background: #eee;
  font-size: 14px;
  line-height: 50px;
  padding: 10px 0;
  text-transform: uppercase
}

.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-popover {
  border-color: #f3f3f3
}

.fc-basic-view .fc-day-number, .fc-basic-view .fc-week-number {
  padding: 2px 5px
}

.fc-button {
  background: #f1f1f1;
  border: none;
  color: #797979;
  text-transform: capitalize;
  box-shadow: none !important;
  border-radius: 3px !important;
  margin: 0 3px !important;
  padding: 6px 12px !important;
  height: auto !important
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 16px
}

.fc-state-hover {
  background: #f3f3f3
}

.fc-state-highlight {
  background: #f0f0f0
}

.fc-state-down, .fc-state-active, .fc-state-disabled {
  background-color: #7638ff !important;
  color: #fff !important;
  text-shadow: none !important
}

.fc-cell-overlay {
  background: #f0f0f0
}

.fc-unthemed .fc-today {
  background: #fff
}

.fc-event {
  border-radius: 2px;
  border: none;
  color: #fff !important;
  cursor: move;
  font-size: 13px;
  margin: 1px 7px;
  padding: 5px;
  text-align: center
}

.fc-basic-view td.fc-week-number span {
  padding-right: 8px;
  font-weight: var(--website-font-weight-normal);
  font-family: roboto, sans-serif
}

.fc-basic-view td.fc-day-number {
  padding-right: 8px;
  font-weight: var(--website-font-weight-normal);
  font-family: roboto, sans-serif
}

.event-form .input-group .form-control {
  height: 40px
}

.submit-section {
  text-align: center;
  margin-top: 40px
}

.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: var(--website-font-weight-normal);
  min-width: 200px;
  padding: 10px 20px
}

.dropdown-action {
  margin-bottom: 5px
}

.dropdown-action .dropdown-toggle:after {
  display: none
}

.action-icon {
  color: #333;
  font-size: 18px;
  display: inline-block
}

.table-inbox input[type=radio], .table-inbox input[type=checkbox] {
  cursor: pointer
}

.mail-list {
  list-style: none;
  padding: 0
}

.mail-list > li > a {
  color: #333;
  display: block;
  padding: 10px
}

.mail-list > li.active > a {
  color: #7638ff;
  font-weight: var(--website-font-weight-normal);
}

.unread .name, .unread .subject, .unread .mail-date {
  color: #000;
  font-weight: var(--website-font-weight-normal);
}

.table-inbox .starred.fas.fa-star {
  color: #ffd200
}

.table.table-inbox > tbody > tr > td, .table.table-inbox > tbody > tr > th, .table.table-inbox > tfoot > tr > td, .table.table-inbox > tfoot > tr > th, .table.table-inbox > thead > tr > td, .table.table-inbox > thead > tr > th {
  border-bottom: 1px solid #f2f2f2;
  border-top: 0
}

.table-inbox {
  font-size: 15px;
  margin-bottom: 0
}

.table.table-inbox thead {
  background-color: #fff
}

.note-editor.note-frame {
  border: 1px solid #ddd;
  box-shadow: inherit
}

.note-editor.note-frame .note-statusbar {
  background-color: #fff
}

.note-editor.note-frame.fullscreen {
  top: 60px
}

.note-editor.note-frame .btn-light {
  background-color: #f9f9f9;
  box-shadow: unset;
  color: #333
}

.mail-title {
  font-weight: var(--website-font-weight-normal);
  text-transform: uppercase
}

.form-control.search-message {
  border-color: #ccc;
  border-radius: 4px;
  height: 38px;
  width: 180px
}

.table-inbox tr {
  cursor: pointer
}

table.table-inbox tbody tr.checked {
  background-color: #ffc
}

.mail-label {
  font-size: 16px !important;
  margin-right: 5px
}

.attachments {
  list-style: none;
  margin: 0;
  padding: 0
}

.attachments li {
  border: 1px solid #eee;
  float: left;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 180px
}

.attach-info {
  background-color: #f4f4f4;
  padding: 10px
}

.attach-file {
  color: #777;
  font-size: 70px;
  padding: 10px;
  min-height: 138px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: center
}

.attach-file img {
  height: auto;
  max-width: 100%
}

.mailview-header {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  padding-bottom: 15px
}

.mailview-footer {
  border-top: 1px solid #ddd;
  margin-top: 20px;
  padding-top: 15px
}

.mailview-footer .btn-white {
  margin-top: 10px;
  min-width: 102px
}

.sender-img {
  float: left;
  margin-right: 10px;
  width: 40px
}

.sender-name {
  display: block
}

.receiver-name {
  color: #777
}

.mail-view-title {
  font-weight: var(--website-font-weight-normal);
  font-size: 24px;
  margin: 0
}

.mail-view-action {
  float: right
}

.mail-sent-time {
  float: right
}

.inbox-menu {
  display: inline-block;
  margin: 0 0 1.875rem;
  padding: 0;
  width: 100%
}

.inbox-menu li {
  display: inline-block;
  width: 100%
}

.inbox-menu li + li {
  margin-top: 2px
}

.inbox-menu li a {
  color: #333;
  display: inline-block;
  padding: 10px 15px;
  width: 100%;
  text-transform: capitalize;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  transition: .3s ease
}

.inbox-menu li a i {
  font-size: 16px;
  padding-right: 10px;
  color: #878787
}

.inbox-menu li a:hover, .inbox-menu li.active a, .inbox-menu li a:focus {
  background: rgba(33, 33, 33, .05)
}

.compose-btn {
  margin-bottom: 1.875rem
}

.compose-btn a {
  font-weight: var(--website-font-weight-normal);
  padding: 8px 15px
}

.error-page {
  align-items: center;
  color: #1f1f1f;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex
}

.error-page .main-wrapper {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  width: 100%;
  min-height: unset
}

.error-box {
  margin: 0 auto;
  max-width: 480px;
  padding: 1.875rem 0;
  text-align: center;
  width: 100%
}

.error-box h1 {
  color: #7638ff;
  font-size: 10em
}

.error-box p {
  margin-bottom: 1.875rem
}

.error-box .btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: var(--website-font-weight-normal);
  min-width: 200px;
  padding: 10px 20px
}

.main-wrapper {
  width: 100%;
  height: 100vh;
  min-height: 100vh
}

.page-wrapper {
  margin-left: 270px;
  padding-top: 45px;
  position: relative;
  transition: all .4s ease
}

.page-wrapper > .content {
  padding: 27px
}

.page-header {
  margin-bottom: 1.875rem
}

.page-header .breadcrumb {
  background-color: transparent;
  color: #6c757d;
  font-size: 1rem;
  font-weight: var(--website-font-weight-normal);
  margin-bottom: 0;
  padding: 0
}

.page-header .breadcrumb a {
  color: #333
}

.page-title {
  color: #333;
  margin-bottom: 5px
}

.login-body {
  display: table;
  height: 100vh;
  min-height: 100vh
}

.logo-dark {
  max-width: 180px;
  margin: auto;
  display: block
}

.login-wrapper {
  width: 100%;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.login-wrapper .loginbox {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 13px 0 rgba(82, 63, 105, .05);
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  margin: 1.875rem auto;
  max-width: 450px;
  width: 100%
}

.login-wrapper .loginbox .login-left {
  align-items: center;
  background: white;
  border-radius: 6px 0 0 6px;
  flex-direction: column;
  justify-content: center;
  padding: 80px;
  width: 450px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex
}

.login-wrapper .loginbox .login-right {
  align-items: center;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: center;
  padding: 2rem;
  width: 450px
}

.login-wrapper .loginbox .login-right .login-right-wrap {
  max-width: 100%;
  flex: 0 0 100%
}

.login-wrapper .loginbox .login-right h1 {
  font-size: 25px;
  font-weight: var(--website-font-weight-normal);
  margin-bottom: 5px;
  text-align: center
}

.account-subtitle {
  color: #4c4c4c;
  font-size: 17px;
  margin-bottom: 1.875rem;
  text-align: center
}

.login-wrapper .loginbox .login-right .forgotpass a {
  color: #a0a0a0
}

.login-wrapper .loginbox .login-right .forgotpass a:hover {
  color: #333;
  text-decoration: underline
}

.login-wrapper .loginbox .login-right .dont-have {
  color: #a0a0a0;
  margin-top: 1.875rem
}

.login-wrapper .loginbox .login-right .dont-have a {
  color: #333
}

.login-wrapper .loginbox .login-right .dont-have a:hover {
  text-decoration: underline
}

.social-login {
  text-align: center
}

.social-login > span {
  color: #757575;
  margin-right: 8px
}

.social-login > a {
  background-color: #ccc;
  border-radius: 4px;
  color: #fff;
  display: inline-block;
  font-size: 18px;
  height: 32px;
  line-height: 32px;
  margin-right: 6px;
  text-align: center;
  width: 32px
}

.cursor-pointer {
  cursor: pointer
}

.social-login > a:last-child {
  margin-right: 0
}

.social-login > a.facebook {
  background-color: #4b75bd
}

.social-login > a.google {
  background-color: #fe5240
}

.login-or {
  color: #757575;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
  position: relative
}

.or-line {
  background-color: #e5e5e5;
  height: 1px;
  margin-bottom: 0;
  margin-top: 0;
  display: block
}

.span-or {
  background-color: #fff;
  display: block;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  top: 0;
  width: 42px
}

.lock-user {
  margin-bottom: 20px;
  text-align: center
}

.lock-user img {
  margin-bottom: 15px;
  width: 100px
}

.toggle-password {
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  color: #757575;
  cursor: pointer
}

.toggle-password.fa-eye {
  margin-right: 1px
}

.pass-group {
  position: relative
}

.notifications {
  padding: 0
}

.notifications .notification-time {
  font-size: 12px;
  line-height: 1.35;
  color: #bdbdbd
}

.notifications .media {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5
}

.notifications .media:last-child {
  border-bottom: none
}

.notifications .media a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px
}

.notifications .media a:hover {
  background-color: #fafafa
}

.notifications .media > .avatar {
  margin-right: 10px
}

.notifications .media-list .media-left {
  padding-right: 8px
}

.topnav-dropdown-header {
  border-bottom: 1px solid #eee;
  text-align: center
}

.topnav-dropdown-header, .topnav-dropdown-footer {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px
}

.topnav-dropdown-footer {
  border-top: 1px solid #eee
}

.topnav-dropdown-footer a {
  display: block;
  text-align: center;
}

.display-none {
  display: none
}

.user-menu.nav > li > a .badge {
  background-color: #ef3737;
  display: inline;
  height: 7px;
  width: 7px;
  color: #fff;
  position: absolute;
  right: 0;
  top: 10px;
  border-radius: 50%;
  padding: 0
}

.user-menu.nav > li > a > i {
  font-size: 1.5rem;
  line-height: 60px
}

.user-menu.nav > li > a > svg {
  font-size: 1.5rem;
  line-height: 60px
}

.noti-details {
  color: #989c9e;
  margin-bottom: 0
}

.notifications .noti-content {
  height: 290px;
  width: 350px;
  overflow-y: auto;
  position: relative
}

.notification-list {
  list-style: none;
  padding: 0;
  margin: 0
}

.notifications ul.notification-list > li {
  margin-top: 0;
  border-bottom: 1px solid #f5f5f5
}

.notifications ul.notification-list > li:last-child {
  border-bottom: none
}

.notifications ul.notification-list > li a {
  display: block;
  padding: 10px 15px;
  border-radius: 2px
}

.notifications ul.notification-list > li a:hover {
  background-color: #fafafa
}

.notifications ul.notification-list > li .list-item {
  border: 0;
  padding: 0;
  position: relative
}

.topnav-dropdown-header .notification-title {
  display: block;
  float: left;
  font-size: 14px
}

.topnav-dropdown-header .clear-noti {
  color: #f83f37;
  float: right;
  font-size: 12px;
  text-transform: uppercase
}

.noti-time {
  margin: 0
}

.mobile-user-menu {
  color: #fff;
  display: none;
  float: right;
  font-size: 24px;
  height: 60px;
  line-height: 60px;
  padding: 0 20px;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  width: 60px;
  z-index: 10
}

.mobile-user-menu > a {
  color: #1665d8;
  padding: 0
}

.mobile-user-menu > .dropdown-menu {
  min-width: 130px
}

.mobile-user-menu a.dropdown-item {
  padding: 0;
  text-align: center;
  border-bottom: 0;
  line-height: 42px;
  border-bottom: 1px solid #eeee
}

.dash-title {
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
}

.dash-widget-icon {
  align-items: center;
  border-radius: 10px;
  color: #fff;
  display: inline-flex;
  font-size: 1.875rem;
  height: 4rem;
  justify-content: center;
  text-align: center;
  width: 4rem
}

.dash-count {
  font-size: 18px;
  margin-left: 15px;
  padding-top: 5px
}

.page-name h4 {
  font-weight: var(--website-font-weight-normal);
  font-size: 20px
}

.manage-set {
  padding: 15px;
  border-bottom: 1px solid rgba(137, 137, 137, .25);
  display: flex;
  justify-content: space-between;
  align-items: center
}

.member-head.employee-image .avatar > img {
  width: 78px;
  -o-object-fit: cover;
  object-fit: cover
}

.member-imgs img, .member-img img {
  width: 70px;
  border: 1px solid #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
  border-radius: 50%
}

.employee_status img {
  width: 70px;
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, .25)
}

a.manage-link {
  font-size: 12px;
  text-decoration: underline;
  color: #000
}

.page-name label {
  font-size: 20px;
  margin: 0;
  color: #000
}

.breadcrumb-path {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(137, 137, 137, .25)
}

.activity-box {
  position: relative
}

.activity-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative
}

.activity .activity-list > li .activity-user {
  height: 32px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 12px;
  left: 8px;
  width: 32px
}

.activity .activity-list > li .activity-content {
  background-color: #fff;
  margin: 0 0 0 40px;
  padding: 0;
  position: relative
}

.activity-list > li {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
  border: 1px solid #e7e7e7
}

.activity-list > li:last-child .activity-content {
  margin-bottom: 0
}

.activity-user .avatar {
  height: 32px;
  line-height: 32px;
  margin: 0;
  width: 32px
}

.activity-list > li .activity-user {
  background: #fff;
  height: 32px;
  left: -7px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 3px;
  width: 32px
}

.activity-list > li .activity-content {
  background-color: #fff;
  margin: 0 0 20px 40px;
  padding: 0;
  position: relative
}

.activity-list > li .activity-content .timeline-content {
  color: #9e9e9e
}

.activity-list > li .activity-content .timeline-content a.name {
  color: #000
}

.activity-list > li .time {
  color: #bdbdbd;
  display: block;
  font-size: 12px;
  line-height: 1.35
}

.activity .timeline-content > a {
  color: #1D5795;
  font-weight: var(--website-font-weight-normal);
}

.activity-delete {
  background-color: #ffe5e6;
  border: 1px solid #fe0000;
  color: #fe0000;
  display: none;
  font-size: 20px;
  height: 20px;
  line-height: 15px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 20px
}

.activity-delete:hover {
  background-color: #fe0000;
  color: #fff
}

.activity-list > li:hover .activity-delete {
  display: inline-block
}

.activity {
  width: 100%
}

.manage-name label {
  display: block;
  font-size: 16px;
  font-weight: var(--website-font-weight-normal);
  color: #000;
  margin-bottom: 0
}

.manage-name span {
  font-size: 8px;
  font-weight: var(--website-font-weight-normal);
  color: #000
}

.breadcrumb-path ul.breadcrumb {
  background: 0 0;
  padding: 0;
  margin: 0
}

.dash-activity .slimScrollDiv {
  height: 100% !important;
  max-height: 290px
}

.breadcrumb a, .breadcrumb li {
  color: #000;
  font-size: 14px;
  align-items: center;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex
}

.activity_scroll {
  max-height: 285px
}

.leave-set {
  padding: 15px;
  border-bottom: 1px solid rgba(137, 137, 137, .25);
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center
}

.leave-set i {
  color: #fff;
  font-size: 10px
}

span.leave-inactive {
  background: #dc3545
}

.leave-set img {
  width: 12px
}

.leave-viewall {
  padding: 25px 10px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: flex-end
}

.dash-activity {
  padding: 10px;
  background: #fff;
  box-shadow: 0 0 13px 0 rgb(82 63 105/5%);
  border-radius: 10px;
  width: 100%
}

.dash-activity h3 {
  background: #f9fafb url(assets/img/headmap.png) no-repeat 100%;
  padding: 30px 20px;
  font-size: 18px;
  font-weight: var(--website-font-weight-normal);
  width: 100%
}

.activity-set {
  border-bottom: 1px solid #e4e7eb;
  padding: 8px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  background: url(assets/img/right-icon.png) no-repeat 96% 50%
}

.leave-viewall.activit {
  padding: 7px 10px
}

.activity-img {
  margin-right: 11px
}

.activity-set:hover {
  background: #f5f5f5 url(assets/img/right-icon.png) no-repeat 96% 50%
}

.activity-content {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column
}

.activity-content label {
  color: #3a3b3f;
  margin: 0
}

.activity-content span {
  color: #9ea0a5;
  font-size: 12px
}

.main-wrapper .leave-viewall a {
  color: #1070ca;
  font-weight: 500
}

.leave-set:hover {
  background: #f5f5f5
}

.leave-set span {
  margin-right: 20px;
  padding: 2px 6px;
  border-radius: 50%;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center
}

span.leave-active {
  background: #0C79B7
}

.leave-set label {
  margin: 0;
  font-size: 12px;
}

.breadcrumb-path h3 {
  font-size: 20px;
  font-weight: var(--website-font-weight-normal)
}

.dashbord-link ul {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  padding: 0
}

.dashbord-link li {
  width: 50%;
  text-align: center
}

.dashbord-link li:first-child {
  padding-right: 20px
}

.dashbord-link a {
  font-size: 20px;
  width: 100%;
  display: block;
  padding: 10px;
  border-radius: 10px;
  font-weight: var(--website-font-weight-normal)
}

a.btn-dash {
  background: #1665d8;
  color: #fff;
  border: 1px solid #1665d8;
  width: 100%;
  display: block;
  text-align: center;
  padding: 21px;
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  border-radius: 10px
}

.dashbord-link li:last-child {
  padding-left: 15px
}

a.btn-dash:hover {
  background: 0 0;
  color: #1665d8;
  box-shadow: 0 4px 10px 0 rgba(137, 137, 137, .25)
}

a.btn-emp {
  background: #e4e7eb;
  color: #000;
  border: 1px solid transparent;
  width: 100%;
  display: block;
  text-align: center;
  padding: 21px;
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  border-radius: 10px
}

a.btn-emp:hover {
  background: 0 0;
  border: 1px solid #000;
  box-shadow: 0 4px 10px 0 rgba(137, 137, 137, .25)
}

.board1 .card-body {
  padding: 15px;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid transparent
}

.card.board1 {
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(137, 137, 137, .25);
  color: #fff;
  margin: 0
}

.card-list {
  border-radius: 10px
}

.btn-action {
  background: #f7f7f7;
  color: #3246d3;
  border-radius: 10px;
  padding: 5px 9px
}

.btn-action:hover {
  background: #3246d3;
  color: #f7f7f7
}

.card-lists .card-header {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  border-color: #e9e2e2;
  padding: 25px
}

.card-lists .card-header ul {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  padding: 0
}

.card-list .card-header {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 10px 0 0;
  border-color: #e9e2e2;
  padding: 15px
}

a.plus {
  border: 1px solid #ebecf1;
  line-height: 50px;
  padding: 14px 12px;
  margin-right: 10px;
  border-radius: 5px
}

a.delete {
  border: 1px solid #ebecf1;
  line-height: 50px;
  padding: 14px 12px;
  border-radius: 5px
}

.card .card-header .card-title {
  margin-bottom: 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: var(--website-font-weight-normal);
}

.card-title-dash {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: var(--website-font-weight-normal);
}

.card .card-header .card-titles {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: var(--website-font-weight-normal)
}

.team-view {
  width: 100%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  padding: 10px;
  background: #fff;
  transition: all .5s;
  border-radius: 20px
}

.team-img {
  margin-right: 13px
}

.team-img img {
  border-radius: 50%;
  width: 40px;
  height: 40px
}

.card-set {
  width: 100%;
  background-color: #ebecf1;
  border-radius: 50px
}

.card-body-inner {
  border: 1px solid #f2eeee;
  padding: 30px 20px
}

.card-days {
  text-align: center;
  padding-right: 25px;
  line-height: 30px;
  color: #fff
}

.card-days {
  width: 50%;
  background-color: #00882e;
  border-radius: 50px
}

.card-range {
  border: 1px solid #f2eeee;
  padding: 20px;
  border-radius: 10px
}

.team-list {
  align-items: center;
  transition: all .5s;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 1px solid #f2eeee
}

.team-action {
  width: 20%;
  display: none;
  transform: translateX(100%);
  transition: all .5s;
  padding-left: 10px;
  padding-right: 10px
}

.card-body.dash-activity {
  padding: 0;
  padding-bottom: 10px
}

.team-action ul {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  color: #fff;
  padding: 0
}

.checkworking input[type=checkbox] {
  position: absolute;
  z-index: -1;
  opacity: 0
}

.checkworking input[type=checkbox]:checked ~ label::before {
  color: #fff;
  border-color: #1D5795;
  background-color: #1D5795
}

.checkworking input[type=checkbox]:checked ~ label::after {
  content: "âœ“";
  position: absolute;
  left: 4px;
  color: #fff
}

.company-path.checkworking input[type=checkbox]:checked ~ label::after {
  top: 1px;
  left: 5px
}

.checkworking {
  padding: 15px 0
}

.company-path.checkworking label::before {
  background: 0 0;
  width: 20px;
  height: 20px;
  position: absolute;
  content: "";
  left: 0;
  border: 1px solid #e5e5e5;
  top: 0;
  border-radius: 5px
}

.checkworking ul {
  padding: 0;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center
}

.checkworking ul li {
  margin-right: 25px;
  position: relative
}

.checkworking ul li label {
  padding-left: 25px
}

.checkworking label::before {
  background: #ececec;
  width: 20px;
  height: 20px;
  position: absolute;
  content: "";
  left: 0;
  border: 1px solid #9ea0a5;
  top: 0;
  border-radius: 5px
}

.team-action ul li {
  width: 50%;
  text-align: center
}

.company-set ul {
  padding: 0
}

.company-set li {
  margin-bottom: 30px;
  position: relative
}

.company-path input[type=checkbox] {
  display: none
}

.company-path {
  padding: 0;
  padding-left: 30px
}

.company-path h3 {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px
}

.company-path label {
  font-size: 12px;
  font-weight: 700;
  color: #000
}

.company-path label span {
  display: block;
  font-weight: 400;
  margin-top: 5px
}

.company-path p {
  font-size: 12px;
  font-weight: 500
}

.team-action ul li a {
  font-size: 20px;
  cursor: pointer
}

.team-list:hover {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex
}

.team-list:hover .team-action {
  display: block;
  transform: translateX(0);
  transition: all .5s
}

.team-content {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  flex-direction: column
}

.team-list:hover {
  background: #3629b7;
  border-radius: 30px 20px 20px 30px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0/10%)
}

.team-view label {
  font-size: 16px;
  font-weight: var(--website-font-weight-normal);
  margin-bottom: 0;
  color: #343434
}

.team-view span {
  color: #606060;
  font-size: 12px
}

.board1.fill1 {
  background: #1D5795
}

.card.board1.fill2 {
  background: #ff8d07
}

.card.board1.fill3 {
  background: #dc3545
}

.card.board1.fill4 {
  background: #00882e
}

.card_widget_img {
  background: #fff;
  padding: 10px;
  border-radius: 10px
}

.card_widget_header label {
  font-weight: 700
}

.card_widget_header h4 {
  font-size: 30px;
  color: #fff;
  font-weight: 700
}

.page-name {
  background: #efefef;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  border-radius: 10px
}

.dash-widget-info h3 {
  margin-bottom: 10px
}

.dash-widget-header {
  align-items: center;
  display: flex
}

.activity-feed {
  list-style: none;
  margin-bottom: 0;
  margin-left: 5px;
  padding: 0
}

.activity-feed .feed-item {
  border-left: 2px solid #e4e8eb;
  padding-bottom: 19px;
  padding-left: 20px;
  position: relative
}

.activity-feed .feed-item:last-child {
  border-color: transparent
}

.activity-feed .feed-item:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #7638ff
}

.activity-feed .feed-item .feed-date {
  display: block;
  position: relative;
  color: #777;
  text-transform: uppercase;
  font-size: 13px
}

.activity-feed .feed-item .feed-text {
  color: #777;
  position: relative
}

.activity-feed .feed-item .feed-text a {
  font-weight: var(--website-font-weight-normal);
}

.modal-icon i {
  font-size: 42px;
  color: #6c757d
}

.modal-footer {
  border-top: 0;
  padding-top: 0;
  padding-bottom: .75rem;
  margin-top: 0.75rem;
}

.add-remove i {
  font-size: 18px;
  cursor: pointer
}

.invoice-item .invoice-logo {
  margin-bottom: 30px
}

.invoice-item .invoice-logo img {
  width: auto;
  max-height: 52px
}

.invoice-item .invoice-text h2 {
  color: #272b41;
  font-size: 36px;
  font-weight: var(--website-font-weight-normal)
}

.invoice-item .invoice-details {
  text-align: right;
  font-weight: var(--website-font-weight-normal)
}

.invoice-item .invoice-details strong {
  color: #272b41
}

.invoice-item .invoice-details-two {
  text-align: left
}

.invoice-item .invoice-text {
  padding-top: 42px;
  padding-bottom: 36px
}

.invoice-item .invoice-text h2 {
  font-weight: 400
}

.invoice-info {
  margin-bottom: 30px
}

.invoice-info p {
  margin-bottom: 0
}

.invoice-info.invoice-info2 {
  text-align: right
}

.invoice-item .customer-text {
  font-size: 18px;
  color: #272b41;
  font-weight: var(--website-font-weight-normal);
  margin-bottom: 8px;
  display: block
}

.invoice-table tr th, .invoice-table tr td, .invoice-table-two tr th, .invoice-table-two tr td {
  color: #272b41;
  font-weight: var(--website-font-weight-normal);
  padding: 10px 20px;
  line-height: inherit
}

.invoice-table tr td, .invoice-table-two tr td {
  font-weight: 500
}

.invoice-table-two {
  margin-bottom: 0
}

.invoice-table-two tr th, .invoice-table-two tr td {
  border-top: 0
}

.invoice-table-two tr td {
  text-align: right
}

.invoice-info h5 {
  font-size: 16px;
  font-weight: 500
}

.other-info {
  margin-top: 10px
}

.file {
  visibility: hidden;
  position: absolute
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: -inline-flexbox;
  display: inline-flex;
  vertical-align: middle
}

.settings-menu ul {
  display: block;
  padding: 0
}

.settings-menu ul li + li {
  margin-top: 15px
}

.settings-menu ul li a {
  color: #455560;
  padding: 0;
  border: 0 !important;
  display: inline-block
}

.settings-menu ul li a:hover, .settings-menu ul li a:focus {
  background-color: transparent !important;
  border: 0 !important
}

.settings-menu ul li a.active {
  color: #7638ff !important;
  border: 0
}

.settings-menu ul li i {
  margin-right: 10px;
  font-size: 20px;
  min-width: 25px
}

.toggle-switch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer
}

.toggle-switch:not(.form-group) {
  margin-bottom: 0
}

.toggle-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0
}

.toggle-switch-content {
  -ms-flex: 1;
  flex: 1;
  margin-left: .5rem
}

.toggle-switch-label {
  position: relative;
  display: block;
  width: 3rem;
  height: 2rem;
  background-color: #e7eaf3;
  background-clip: content-box;
  border: .125rem solid transparent;
  border-radius: 6.1875rem;
  transition: .3s
}

.toggle-switch-indicator {
  position: absolute;
  left: .125rem;
  bottom: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  -webkit-transform: initial;
  transform: initial;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25);
  border-radius: 50%;
  -webkit-transform: translate3d(0, 50%, 0);
  transform: translate3d(0, 50%, 0);
  transition: .3s
}

.toggle-switch-input:checked + .toggle-switch-label {
  background-color: #7638ff
}

.toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
  -webkit-transform: translate3d(1.025rem, 50%, 0);
  transform: translate3d(1.025rem, 50%, 0)
}

.toggle-switch-input.is-valid + .toggle-switch-label {
  background-color: #00c9a7
}

.toggle-switch-input.is-invalid + .toggle-switch-label {
  background-color: #ed4c78
}

.toggle-switch-input:disabled + .toggle-switch-label {
  background-color: rgba(231, 234, 243, .5)
}

.toggle-switch-input:checked:disabled + .toggle-switch-label {
  background-color: rgba(55, 125, 255, .5)
}

.toggle-switch-sm .toggle-switch-label {
  width: 2.5rem;
  height: 1.6125rem
}

.toggle-switch-sm .toggle-switch-indicator {
  width: 1.20938rem;
  height: 1.20938rem
}

.toggle-switch-sm .toggle-switch-input:checked + .toggle-switch-label .toggle-switch-indicator {
  -webkit-transform: translate3d(.81094rem, 50%, 0);
  transform: translate3d(.81094rem, 50%, 0)
}

.cal-icon {
  position: relative;
  width: 100%
}

.cal-icon:after {
  color: #979797;
  content: "\f073";
  display: block;
  font-family: "font awesome 5 free";
  font-weight: 900;
  font-size: 15px;
  margin: auto;
  position: absolute;
  right: 15px;
  top: 10px
}

.profile-cover {
  position: relative;
  padding: 1.75rem 2rem;
  border-radius: .75rem;
  height: 10rem
}

.profile-cover-wrap {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 10rem;
  background-color: #e7eaf3;
  border-radius: .75rem
}

.card-body-inner h3 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #000
}

.card-body-inner label {
  font-size: 15px;
  color: #000;
  font-weight: 500;
  display: block
}

.card-range {
  margin-bottom: 30px
}

.card-body-inner ul {
  padding: 0;
  display: flex
}

.btn-approved {
  background: #00882e;
  padding: 10px 45px;
  color: #fff;
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  border: 1px solid #00882e;
  border-radius: 10px
}

.card-body-inner ul li {
  margin-right: 15px
}

.btn-approved:hover {
  background: 0 0;
  color: #00882e
}

.profile-cover-img {
  width: 100%;
  height: 10rem;
  -o-object-fit: cover;
  object-fit: cover;
  vertical-align: top;
  border-radius: .75rem
}

.cover-content {
  position: relative;
  z-index: 1;
  padding: 1rem 2rem;
  position: absolute;
  bottom: 0;
  right: 0
}

.custom-file-btn {
  position: relative;
  overflow: hidden
}

.custom-file-btn-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0
}

.member-info ul {
  padding: 0
}

.member-info ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px
}

.member-info ul li label {
  margin: 0;
  font-size: 15px;
  font-weight: 400
}

.manage-img img {
  width: 36px;
  border-radius: 50%
}

.member-info ul li span {
  color: #1665d8;
  font-weight: 700
}

.member-date ul {
  padding: 0
}

a.btn.btn-doc {
  background: #1D5795;
  color: #fff;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  padding: 13px 33px;
  border: 1px solid #1D5795
}

a.btn.btn-doc:hover {
  background: 0 0;
  color: #1D5795
}

a.btn-addmember {
  background: #1D5795;
  color: #fff;
  padding: 17px 25px;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  display: inline-block;
  border-radius: 10px;
  border: 1px solid #0C79B7;
}

.member-date li label {
  margin: 0;
  font-size: 15px;
  color: #000
}

.create-height {
  height: 550px
}

.employee-head h2.doc-head {
  color: #000
}

.file-uploads input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 70px;
  background-color: rgba(19, 33, 68, .25);
  transition: .2s
}

.card-lists .card-header label {
  margin: 0;
  color: #000;
  font-weight: 400;
  font-size: 15px
}

.form-popups {
  position: relative
}

.file-uploads span {
  position: absolute;
  right: 0;
  height: 70px;
  background: #3629b7;
  padding-top: 23px;
  width: 30%;
  color: #fff;
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  border-radius: 0 5px 5px 0
}

.profile-height {
  height: 170px
}

.member-formcontent-path .member-date a {
  border: 1px solid #ebecf1;
  padding: 5px;
  border-radius: 5px;
  color: #1D5795
}

.file-uploads {
  position: relative;
  height: 80px
}

.file-uploads input[type=file] {
  border: 1px solid #ebecf1
}

.file-uploads label {
  width: 100%;
  height: 70px;
  border: 1px solid #ebecf1;
  border-radius: 5px
}

.employee-contents ul {
  padding: 0
}

a.btn-addmembers {
  background: #1D5795;
  color: #fff;
  padding: 10px 25px;
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  border-radius: 10px;
  border: 1px solid #1D5795;
  display: inline-block
}

a.btn-addmembers:hover {
  background: 0 0;
  color: #1D5795;
  border: 1px solid #1D5795;
}

.employee-contents ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  padding: 15px;
  border-radius: 10px
}

.employee-contents li label img {
  margin-right: 5px
}

.employee-contents li label {
  margin: 0;
  font-weight: var(--website-font-weight-normal);
}

.card-body .employee-team .employee-contents a {
  border: 1px solid #ebecf1;
  padding: 5px;
  border-radius: 5px;
  color: #1D5795
}

.profile-cover-avatar {
  display: -ms-flexbox;
  display: flex;
  margin: -6.3rem auto .5rem;
  border: 3px solid #fff;
  border-radius: 50%
}

.profile-cover-avatar input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 33, 68, .25);
  border-radius: 50%;
  transition: .2s
}

.avatar-edit {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  color: #677788;
  background-color: #fff;
  border-radius: 50%;
  transition: .2s;
  box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25)
}

.avatar-edit svg {
  width: 18px
}

.card-table div.table-responsive > div.dataTables_wrapper > div.row:first-child {
  padding: 1.5rem 1.5rem 0
}

.card-table div.table-responsive > div.dataTables_wrapper > div.row:last-child {
  padding: 0 1.5rem 1.5rem
}

#filter_inputs {
  display: none
}

.input-label {
  display: block;
  color: #1e2022;
  font-size: .875rem
}

.submit-section {
  text-align: center;
  margin-top: 40px
}

.submit-btn {
  border-radius: 50px;
  font-size: 18px;
  font-weight: var(--website-font-weight-normal);
  min-width: 200px;
  padding: 10px 20px
}

.card-body-height {
  height: 28rem;
  overflow: hidden;
  overflow-y: auto
}

.profile-cover-avatar .avatar-img {
  display: block;
  max-width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  border-radius: 50%
}

.report-title {
  font-size: 1.5rem;
  text-decoration: underline;
  text-align: center;
  margin-bottom: 1.5rem
}

.report-table {
  border-bottom: 1px solid rgba(0, 0, 0, .05) !important;
  margin-bottom: 20px
}

.report-table th, .report-table td {
  border: 0 !important
}

.report-table td {
  color: #777
}

.report-table td.report-total {
  color: #333
}

.table.report-table > tbody > tr > th, .table.report-table > tbody > tr > td {
  padding: 0 0 10px
}

.table.report-table tbody + tbody {
  border-top: 0
}

.total-report-table tr th, .total-report-table tr td {
  color: #272b41;
  font-weight: var(--website-font-weight-normal);
  font-size: 20px;
  line-height: inherit
}

.total-report-table tr td {
  font-weight: 700;
  text-align: right;
  color: #7638ff
}

.total-report-table tr th, .total-report-table tr td {
  border-top: 0
}

.table.total-report-table > tbody > tr > th, .table.total-report-table > tbody > tr > td {
  padding: 0
}

.head-link-set {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.head-link-set ul {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 0;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
}

.create-forms {
  width: 100%;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  border-radius: 10px
}

.create-formheads {
  padding: 25px 10px;
  border-bottom: 1px solid rgba(137, 137, 137, .25)
}

.employee-contents p {
  color: #000;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 25px
}

.create-formheads h2 {
  font-size: 18px;
  font-weight: 700
}

.form-creation {
  padding: 25px
}

.form-group label {
  display: block;
  font-size: 15px;
  color: #000;
  font-weight: 400
}

.form-set {
  padding: 20px 20px 0;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  border-radius: 10px
}

.btn-save {
  background: #00882e;
  width: 100%;
  padding: 20px;
  color: #fff;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  border: 1px solid #00882e;
  border-radius: 5px
}

.btn-save:hover {
  background: 0 0;
  color: #00882e
}

span.mandatory {
  color: red
}

.creation-btn ul {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between
}

.action_label3 svg {
  width: 14px;
  margin-left: 15px
}

.form-group select, .form-group input[type=text] {
  width: 100%;
  height: 70px;
  border: 1px solid #d1d2d7;
  padding: 0 30px;
  font-size: 15px;
  color: #000;
  border-radius: 5px
}

.form-group input[type=text]:disabled {
  background: #e4e7eb;
  cursor: no-drop
}

.form-group textarea {
  height: 115px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ebecf1;
  padding: 15px 30px;
  color: #000
}

.form-btn a.btn {
  width: 210px;
  padding: 19px 15px;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  border-radius: 10px;
  font-size: 20px;
  border: 1px solid transparent
}

a.btn.btn-apply:hover {
  border: 1px solid #00882e
}

.btn-secondary {
  background: #dc3545
}

.customize_popup button.btn.btn-secondary:hover {
  background: 0 0;
  color: #dc3545;
  border: 1px solid #dc3545
}

.btn-apply:hover {
  background: 0 0;
  border: 1px solid;
  color: #00882e
}

.head-link-set li a {
  padding: 20px;
  display: block;
  text-align: center;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  box-shadow: 0 4px 10px #89898940;
  color: #000
}

.employee_status {
  min-height: 170px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center
}

.employee_status label {
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  margin-top: 15px
}

label.employee_count {
  width: 100%;
  background: #fff;
  padding: 23px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  margin: 0;
  font-size: 20px;
  color: #000;
  font-weight: var(--website-font-weight-normal);
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 1px solid #dee2e6
}

.employee_grid {
  text-align: center;
  box-shadow: 0 0 10px rgb(137 137 137/25%);
  padding: 25px;
  border-radius: 10px;
  margin-bottom: 30px
}

.paging_simple_number a.page-link {
  border: 0
}

.dataTables_info {
  color: #000;
  font-size: 15px;
  font-weight: 500
}

.previous a.page-link.btnnavingation {
  background: #dadada;
  padding: 5px 15px;
  color: #fff
}

.next a.page-link.btnnavingation {
  background: #1665d8;
  padding: 5px 15px;
  color: #fff;
  border-radius: 0 5px 5px 0
}

.pagination_path ul.pagination {
  justify-content: flex-end;
  align-items: center
}

.employee_grid h5 {
  margin: 10px 0;
  color: #1B2660;
  font-size: 18px;
  font-weight: 700
}

.employee_grid label {
  color: #373d3f;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal);
  display: block
}

.form-week li {
  margin: 5px
}

.member-formcontent {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.member_link a.collapsed svg {
  transform: rotate(0deg);
  transition: all .5s
}

.member_link a svg {
  transform: rotate(180deg);
  transition: all .5s
}

.member-head {
  align-items: center;
  display: flex
}

.member-head > h2 {
  font-size: 20px;
  margin-right: 15px;
  font-weight: var(--website-font-weight-normal)
}

.member_link a {
  background: #e0e0e0;
  padding: 13px;
  border-radius: 5px;
  color: #606060;
  width: 50px;
  height: 50px
}

.page-wrapper .content .employee_grid a {
  color: #1D5795;
  text-decoration: underline
}

.table-set {
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  background: #fff;
  border-radius: 10px
}

.btn-apply {
  background: #00882e;
  padding: 9px 27px;
  font-size: 20px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #00882e;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  font-weight: var(--website-font-weight-normal);
  margin-right: 18px
}

.btn-applys {
  background: #00882e;
  padding: 9px 27px;
  font-size: 20px;
  border-radius: 5px;
  color: #fff;
  border: 1px solid #00882e;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  font-weight: var(--website-font-weight-normal);
  margin-right: 18px
}

.btn-applys:hover {
  background: 0 0;
  color: #00882e
}

.form-btn a.btn-applys {
  width: 210px;
  background: #00882e;
  color: #fff;
  font-weight: var(--website-font-weight-normal);
  padding: 10px 15px;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  border-radius: 5px;
  font-size: 20px;
  border: 1px solid transparent
}

.form-btn a.btn-applys:hover {
  background: 0 0;
  border: 1px solid #00882e;
  color: #00882e
}

.table-heading {
  padding: 15px;
  margin-top: 10px
}

.table-heading h2, .table-heading span {
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  color: #272e48;
}

a.btn-view {
  padding: 23px 10px;
  display: block;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  text-align: center;
  background: #fff;
  border-radius: 10px;
  color: #373d3f;
  font-size: 20px
}

.form-btn .btn-secondary:hover {
  border: 1px solid #dc3545;
  color: #dc3545;
  background: 0 0
}

a.btn-view.active, a.btn-view:hover {
  background: #0C79B7;
  color: #fff
}

.head-link-set li {
  min-width: 160px
}

.head-link-set li a.active {
  background: #1D5795;
  border-radius: 5px;
  color: #fff
}

.head-link-set li a:hover {
  background: #5D8FC5;
  border-radius: 5px;
  color: #fff !important;
}

a.btn-add {
  background: #0C79B7;
  color: #fff;
  padding: 14px 20px;
  font-size: 15px;
  border-radius: 10px;
  font-weight: var(--website-font-weight-normal);
  transition: all .5s;
  border: 1px solid #0C79B7
}

.head-link-set li:not(:last-child) a {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.head-link-set li:not(:last-child) a:hover {
  border-radius: 0;
}
.head-link-set li:last-child a {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.head-link-set li:last-child a:hover {
  border-radius: 0;
}

.card-titles span {
  color: #000;
  display: block;
  font-size: 15px;
  font-weight: 400;
  margin-top: 5px
}

a.btn-add:hover {
  box-shadow: 0 0 100px 110px #ffff inset;
  color: #0C79B7;
}

.employee-team {
  box-shadow: 0 0 10px 0 rgb(137 137 137/25%);
  margin-bottom: 20px;
  border-radius: 10px
}

.employee-head {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.employee-head .img-table {
  width: 50px
}

.employee-head ul {
  padding: 0;
  display: flex;
  align-items: center
}

.employee-head ul li {
  margin: 0 10px
}

.employee-head li a {
  border: 1px solid #ebecf1;
  padding: 10px;
  border-radius: 10px;
  display: block
}

a.edit_employee {
  color: #1D5795
}

a.edit_delete {
  color: #1D5795
}

.employee-head h2 {
  color: #00882e;
  font-size: 20px;
  font-weight: 700
}

.employee-content {
  display: flex;
  align-items: center;
  justify-content: space-between
}

.card-body .employee-team .employee-contents a.btn-addmember {
  background: #1D5795;
  color: #fff;
  padding: 10px 25px;
  border-radius: 10px;
  border: 1px solid #1D5795;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal)
}

.logo-upload input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  opacity: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 33, 68, .25);
  transition: .2s
}

a.btn-addmember:hover {
  background: 0 0;
  color: #1D5795;
  border: 1px solid #1D5795
}

.customize_popup .modal-content {
  padding: 10px
}

.customize_popup .close {
  color: #fff;
  border: 2px solid #0C79B7;
  padding: 0 3px;
  display: block;
  margin: 0;
  opacity: 1;
  width: 27px;
  border-radius: 5px;
  height: 27px;
  background-color: #0C79B7;
}

.customize_popup .close:hover {
  background: #fff;
  color: #0C79B7
}

.customize_popup .modal-lg {
  width: 700px
}

.customize_popup .modal-header {
  padding: 10px;
}

.customize_popup .modal-title {
  font-size: 25px;
  font-weight: 700
}

.form-popup label {
  display: block;
  color: #000;
  font-size: 15px;
  font-weight: 400
}

.form-popup input[type=text], .form-popup select {
  width: 100%;
  border: 1px solid #d1d2d7;
  height: 60px;
  font-size: 15px;
  padding: 16px
}

.form-popup input[type=text]:hover, .form-popup input[type=text]:active, .form-popup input[type=text]:focus {
  border: 1px solid #747475;
}

.customize_popup .modal-footer {
  justify-content: center;
}

.create-form {
  width: 100%;
  background: #fff;
  padding: 15px 35px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%)
}

.create-formhead h2 {
  font-size: 20px;
  font-weight: 700
}

.create-formcontent {
  padding: 20px 0
}

a.btn-create {
  width: 100%;
  text-align: center;
  display: block;
  background: #ff8d07;
  border: 1px solid #ff8d07;
  color: #fff;
  font-size: 20px;
  padding: 19px 0;
  font-weight: var(--website-font-weight-normal);
  border-radius: 10px
}

.member-registor ul {
  padding: 0;
  border: 1px solid #ebecf1;
  border-radius: 5px
}

.member-formcontents {
  display: flex;
  align-items: center
}

.member-registor ul li {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebecf1
}

.member-registor ul li label, .member-address label {
  font-size: 15px;
  color: #000;
  margin: 0;
  font-weight: 400
}

.member-address label {
  margin-bottom: 15px
}

.member-registor ul li span, .member-address span {
  color: #1665d8;
  font-weight: 700;
  display: block;
  font-size: 15px
}

.member-address {
  border: 1px solid #ebecf1;
  border-radius: 5px;
  padding: 30px
}

a.btn-create:hover {
  color: #ff8d07;
  background: 0 0
}

input.input-form {
  width: 100%;
  height: 70px;
  padding: 0 15px;
  border: 1px solid #d1d2d7;
  border-radius: 10px
}

.create-formhead h2 span {
  color: #000;
  display: block;
  font-size: 15px;
  font-weight: 400;
  margin-top: 5px
}

.create-formhead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(137, 137, 137, .25);
  padding-bottom: 10px
}

.create-form .create-formhead a {
  border: 1px solid #ebecf1;
  padding: 8px;
  color: #1D5795;
  border-radius: 5px
}

a.btn-cancel {
  background: #dc3545;
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
  padding: 19px 0;
  border: 1px solid #dc3545;
  font-size: 20px;
  border-radius: 10px;
  font-weight: var(--website-font-weight-normal)
}

a.btn-cancel:hover {
  color: #dc3545;
  background: 0 0
}

.customize_popup button.btn.btn-primary {
  background: #ff8d07;
  border: 1px solid #ff8d07;
  padding: 10px;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  margin-right: 20px
}

.customize_popup button.btn.btn-primary:hover {
  background: 0 0;
  color: #ff8d07
}

.member-edits ul {
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%
}

.member-edits ul li {
  justify-content: space-between;
  display: flex;
  margin-bottom: 35px;
  align-items: center
}

.member-edits {
  width: 100%;
  padding-bottom: 15px
}

.member-edits ul li label {
  border: 1px solid #ebecf1;
  border-radius: 5px;
  width: 80%;
  padding: 13px;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  margin: 0
}

.edit-link {
  border: 1px solid #ebecf1;
  padding: 12px;
  color: #1D5795;
  display: block;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  margin-right: 10px
}

.add-link {
  border: 1px solid #ebecf1;
  padding: 8px;
  color: #00882e;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px
}

.delete-link {
  border: 1px solid #ebecf1;
  padding: 8px;
  color: #dc3545;
  border-radius: 5px;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center
}

.member-formcontent-path .member-date .btn-primary {
  border: 1px solid #ebecf1;
  padding: 12px 5px;
  border-radius: 5px;
  color: #fff
}

.member-date.member-newformat li {
  border: 0;
  display: block;
  padding: 0;
  margin: 0
}

.btn-new {
  background: #ff8d07;
  border: 1px solid #ff8d07;
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  padding: 19px 50px;
  border-radius: 5px;
  display: inline-block
}

a.btn.btn-head {
  border: 1px solid #ff8d07;
  color: #fff;
  background: #ff8d07;
  padding: 9px 35px;
  font-size: 20px;
  border-radius: 5px;
  font-weight: var(--website-font-weight-normal)
}

.over-view-path ul {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  padding: 0
}

.over-view-path ul li {
  width: 50%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  justify-content: space-between;
  padding-right: 25px;
  text-align: left
}

.over-view-path {
  border-bottom: 1px solid rgba(137, 137, 137, .25);
  padding: 15px
}

.over-view-path ul li label {
  margin: 0;
  font-size: 15px;
  color: #000
}

.over-view-path ul li span {
  color: #1665d8;
  font-size: 15px;
  font-weight: var(--website-font-weight-normal)
}

a.btn.btn-head:hover {
  background: 0 0;
  color: #ff8d07
}

.btn-new:hover {
  background: 0 0;
  color: #ff8d07
}

.member-formcontents .member-edits ul li a {
  border: 1px solid #ebecf1;
  padding: 10px;
  border-radius: 5px;
  color: #1D5795
}

.customize_popup button.btn.btn-secondary {
  background: #dc3545;
  border: 1px solid #dc3545;
  padding: 10px;
  font-size: 20px;
  border-radius: 10px;
  border: 0;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%)
}

.btn-add {
  background: #1D5795;
  color: #fff;
  padding: 10px;
  box-shadow: 0 4px 10px 0 rgb(137 137 137/25%);
  font-size: 15px;
  border-radius: 10px
}

.create-formhead ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center
}

a.edit {
  color: #1D5795
}

a.plus {
  color: #00882e !important
}

a.delete {
  color: #dc3545 !important
}

.create-formhead ul li {
  margin: 0 5px
}

.member-img label {
  font-size: 20px;
  color: #000;
  font-weight: var(--website-font-weight-normal)
}

.member-imgs {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center
}

.member-name label {
  display: block;
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  margin-bottom: 0
}

a.btn.btn-header {
  background: #1D5795;
  color: #fff;
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  padding: 10px 25px
}

a.btn.btn-visit {
  background: #1665d8;
  color: #fff;
  padding: 10px 25px;
  font-size: 20px;
  font-weight: var(--website-font-weight-normal);
  width: auto;
  height: auto
}

.member-name {
  margin-left: 10px
}

.member-date li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 15px
}

.member-date li label {
  font-weight: var(--website-font-weight-normal);
  font-size: 15px
}

.member-name span {
  font-size: 15px;
  color: #000
}

.company-logo {
  text-align: center;
  padding: 85px 0;
  position: relative;
  border: 1px solid #cbd1ee;
  border-radius: 5px
}

.breadcrumb .breadcrumb-item.active {
  color: #000
}

label.logo-upload {
  position: absolute;
  right: 20px;
  top: 20px;
  margin: 0;
  border: 1px solid #ebecf1;
  border-radius: 5px;
  padding: 8px;
  color: #1D5795;
}

.default-set ul {
  padding: 0;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between
}

.default-set label {
  margin: 0;
  font-size: 15px;
  color: #000
}

.default-set {
  padding: 20px 0
}

.default-set span {
  color: #1665d8;
  font-weight: 700
}

.form-week ul {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0
}

.form-week li a {
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-shadow: 0 4px 10px rgba(137, 137, 137, .25);
  display: block
}

.form-week li a.active {
  background: #1D5795;
}

.form-week li a.inactive {
  background: #1D5795
}

.form-week {
  padding: 10px 0
}

.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 30px;
  border-radius: 10px
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 10px;
  bottom: 5px;
  background-color: #fff;
  -webkit-transition: .4s;
  transition: .4s
}

.working-set {
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between
}

.working-set span {
  font-weight: 700;
  font-size: 15px
}

.working-set input:checked + .slider {
  background-color: #00882e
}

.working-set input:focus + .slider {
  box-shadow: 0 0 1px #00882e
}

.working-set input:checked + .slider:before {
  -webkit-transform: translateX(31px);
  -ms-transform: translateX(31px);
  transform: translateX(31px)
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem
  }

  .avatar-xxl .border {
    border-width: 4px !important
  }

  .avatar-xxl .rounded {
    border-radius: 12px !important
  }

  .avatar-xxl .avatar-title {
    font-size: 42px
  }
.login-top {
  display: none;
}

  .avatar-xxl.avatar-away:before, .avatar-xxl.avatar-offline:before, .avatar-xxl.avatar-online:before {
    border-width: 4px
  }
}

@media (min-width: 992px) {
  #toggle_btn {
    align-items: center;
    color: #333;
    display: inline-flex;
    float: left;
    font-size: 30px;
    height: 60px;
    justify-content: center;
    padding: 0
  }

  .bar-icon {
    display: inline-block;
    width: 21px
  }

  .bar-icon span {
    background-color: #1D5795;
    border-radius: 500px;
    display: block;
    float: left;
    height: 3px;
    margin-bottom: 5px;
    width: 21px
  }

  .bar-icon span:nth-child(2) {
    width: 15px
  }

  .bar-icon span:nth-child(3) {
    margin-bottom: 0
  }

  .mini-sidebar .header-left .logo img {
    height: auto;
    max-height: 40px;
    width: auto
  }

  a.btn-emp, a.btn-dash {
    font-size: 16px
  }

  .mini-sidebar .header .header-left .logo {
    display: none
  }

  .mini-sidebar .header-left .logo.logo-small {
    display: block
  }

  .mini-sidebar .sidebar {
    width: 78px
  }

  .mini-sidebar.expand-menu .sidebar {
    width: 270px
  }

  .mini-sidebar .menu-title {
    visibility: hidden;
    white-space: nowrap
  }

  .mini-sidebar.expand-menu .menu-title {
    visibility: visible
  }

  .mini-sidebar .menu-title a {
    visibility: hidden
  }

  .mini-sidebar.expand-menu .menu-title a {
    visibility: visible
  }

  .modal-open.mini-sidebar .sidebar {
    z-index: 1051
  }

  .mini-sidebar .sidebar .sidebar-menu ul > li > a span {
    display: none;
    transition: all .2s ease-in-out;
    opacity: 0
  }

  .mini-sidebar.expand-menu .sidebar .sidebar-menu ul > li > a span {
    display: inline;
    opacity: 1
  }

  .mini-sidebar .page-wrapper {
    margin-left: 78px
  }
}

@media (max-width: 1199px) {
  .head-link-set li {
    min-width: auto
  }
}

@media (max-width: 991.98px) {
  .header .header-left {
    position: absolute;
    width: 100%;
    display: block;
    text-align: center
  }

  .customize_popup .modal-lg {
    width: 97%;
    max-width: 97%
  }

  .head-link-set li {
    min-width: auto
  }

  .select-path {
    margin-top: 15px
  }

  .member-head {
    align-items: center;
    display: block;
    text-align: center
  }

  .employee-head li a {
    border: 0;
    padding: 0
  }

  .head-link-set li a {
    padding: 12px;
    box-shadow: none;
  }

  a.btn-addmember {
    padding: 10px 15px;
    font-size: 16px
  }

  .member-formcontent {
    display: block
  }

  .member_link {
    text-align: center;
    padding: 15px 0 0
  }

  .add-item {
    margin-top: 10px
  }

  .form-set {
    padding: 20px
  }

  .flag-nav {
    display: none
  }

  .card.board1 {
    margin-bottom: 15px
  }

  .mobile_btn {
    color: #1D5795;
    cursor: pointer;
    display: block;
    font-size: 24px;
    height: 60px;
    left: 0;
    line-height: 60px;
    padding: 0 15px;
    position: absolute;
    text-align: center;
    top: 0;
    z-index: 10
  }

  #toggle_btn {
    display: none
  }

  .top-nav-search {
    display: none
  }

  .login-wrapper .loginbox .login-left {
    padding: 80px 50px;
    width: 50%
  }

  .login-wrapper .loginbox .login-right {
    padding: 50px;
    width: 50%
  }

  .mobile-show {
    display: block
  }

  .sidebar {
    margin-left: -300px;
    width: 300px;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease;
    z-index: 1041;
    top: 0
  }

  .sidebar-input .top-nav-search {
    display: inline-block;
    width: 100%;
    margin: 0
  }

  .offcanvas-menu {
    position: relative
  }

  .sidebar-input {
    width: 100%;
    padding: 25px 15px;
    display: inline-block;
    border-bottom: 1px dashed #d5d5d5
  }

  .sidebar-input .top-nav-search form {
    margin: 0
  }

  .page-wrapper {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    -webkit-transition: all .4s ease;
    -moz-transition: all .4s ease;
    transition: all .4s ease
  }

  .chat-window .chat-scroll {
    max-height: calc(100vh - 255px)
  }

  .chat-window .chat-cont-left, .chat-window .chat-cont-right {
    flex: 0 0 100%;
    max-width: 100%;
    transition: left .3s ease-in-out 0s, right .3s ease-in-out 0s;
    width: 100%
  }

  .chat-window .chat-cont-left {
    border-right: 0
  }

  .chat-window .chat-cont-right {
    position: absolute;
    right: calc(-100% + -1.875rem);
    top: 0
  }

  .chat-window .chat-cont-right .chat-header {
    justify-content: start
  }

  .chat-window .chat-cont-right .chat-header .back-user-list {
    display: block
  }

  .chat-window .chat-cont-right .chat-header .chat-options {
    margin-left: auto
  }

  .chat-window.chat-slide .chat-cont-left {
    left: calc(-100% + -1.875rem)
  }

  .chat-window.chat-slide .chat-cont-right {
    right: 0
  }

  input.input-form {
    margin-bottom: 20px;
    height: 50px
  }

  .card.board1 {
    margin-bottom: 15px
  }

  a.btn-create, a.btn-cancel {
    padding: 10px 0
  }

  .member-head > h2 {
    margin-bottom: 25px
  }

  a.btn-emp, a.btn-dash {
    font-size: 16px
  }

  .user-menu.nav > li > a {
    padding: 0 0 0 14px
  }

  .user-menu {
    padding-right: 135px;
  }

  .breadcrumb-path {
    margin-bottom: 20px
  }
}

@media (min-width: 481px) and (max-width: 767.98px) {
  body {
    font-size: .9375rem
  }

  h1, .h1 {
    font-size: 2rem
  }

  h2, .h2 {
    font-size: 1.75rem
  }

  h3, .h3 {
    font-size: 1.5rem
  }

  h4, .h4 {
    font-size: 1.125rem
  }

  h5, .h5 {
    font-size: 1rem
  }

  h6, .h6 {
    font-size: .875rem
  }

  .header .has-arrow .dropdown-toggle:after {
    display: none
  }

  .user-menu.nav > li > a > span:not(.user-img) {
    display: none
  }

  .navbar-nav .open .dropdown-menu {
    float: left;
    position: absolute
  }

  .navbar-nav.user-menu .open .dropdown-menu {
    left: auto;
    right: 0
  }

  .form-group select, .form-group input[type=text] {
    height: 50px
  }

  .form-group .select2-container .select2-selection--single {
    height: 50px
  }

  .form-group .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 10px
  }

  .form-btn a.btn {
    width: auto;
    padding: 10px;
    font-size: 16px
  }

  a.btn.btn-new {
    width: 100%;
    padding: 10px;
    font-size: 14px
  }

  .page-wrapper > .content {
    padding-bottom: 0
  }

  .btn-apply {
    padding: 10px;
    font-size: 14px
  }

  .btn-save {
    padding: 12px
  }

  a.btn.btn-head {
    padding: 4px;
    font-size: 14px
  }

  a.btn.btn-new {
    width: 100%;
    padding: 10px;
    font-size: 14px
  }

  a.btn.btn-visit {
    padding: 5px;
    font-size: 15px
  }

  a.btn.btn-header {
    font-size: 14px;
    font-weight: var(--website-font-weight-normal);
    padding: 5px
  }

  .btn-save {
    padding: 12px
  }

  a.btn.btn-head {
    padding: 4px;
    font-size: 14px
  }

  .header .header-left {
    padding: 0 15px
  }

  .header .header-left .logo {
    display: none
  }

  .header-left .logo.logo-small {
    display: inline-block
  }

  .login-wrapper .loginbox .login-left {
    display: none
  }

  .login-wrapper .loginbox {
    max-width: 450px;
    min-height: unset
  }

  .login-wrapper .loginbox .login-right {
    float: none;
    padding: 1.875rem;
    width: 100%
  }

  .breadcrumb-path h3 {
    font-size: 15px;
    margin-top: 8px;
    padding-left: 30px
  }

  label.employee_count, a.btn-view, a.btn-view {
    padding: 10px
  }

  .slimScrollDiv {
    height: 100% !important
  }

  .page-name label {
    color: #000;
    font-size: 14px
  }

  .top-action-left .float-left {
    float: none !important
  }

  .top-action-left .btn-group {
    margin-bottom: 15px
  }

  .top-action-right {
    text-align: center
  }

  .top-action-right a.btn.btn-white {
    margin-bottom: 15px
  }

  .mail-sent-time {
    float: left;
    margin-top: 10px;
    width: 100%
  }

  .login-wrapper .loginbox .login-right h1 {
    font-size: 22px
  }

  .error-box h1 {
    font-size: 6em
  }

  .error-box .btn {
    font-size: 15px;
    min-width: 150px;
    padding: 8px 20px
  }

  .dash-count {
    font-size: 16px;
    display: inline-block
  }

  .dash-widget-header {
    display: block
  }

  .nav {
    display: block
  }

  .form-btn {
    text-align: center
  }

  .card-body-inner ul {
      display: flex;
      flex-direction: row;
      text-align: center;
      width: 411px;
    margin-top: 10px;
    margin-right: 40px;
  }
  .card-body-inner ul li {
    margin-right: 5px;
  }
  a.btn-addmembers {
    padding: 10px;
    font-size: 14px
  }
  .hide {
    display: none;
  }
  .time-off-list-general {
    display: none;
  }

  .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}

/* CUSTOM BUTTONS WITH ANIMATION */
@media (max-width: 1799px)

.btn-effect-custom {
  margin: 2px !important;
  border-color: #1D5795 !important;
}

.btn-success-custom {
  background-color: #0C79B7 !important;
  color: white;
}

.btn-success-custom:disabled {
  background-color: rgba(12, 121, 183, 0.5) !important;
  cursor: default;
}

.btn-success-custom:disabled:hover {
  border-color: rgba(12, 121, 183, 0.5) !important;
  color: rgba(12, 121, 183, 0.5) !important;
  cursor: default;
}

.btn-success-custom:disabled:hover {
  cursor: default;
}

.squer-btn, .squer-btn:after {
  border-radius: 5px !important;
}

.effect-btn {
  position: relative !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
}

.btn-danger-custom {
  background: #F0F2F9;
  border-color: #0C79B7 !important;
  color: #1D5795;
}

.btn-effect-custom {
  width: 160px;
  height: 40px;
  display: inline-block !important;
  text-align: center !important;
  white-space: unset !important;
  vertical-align: middle !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  border: none !important;
  font-size: 14px !important;
  line-height: 1.2 !important;
  border-radius: 30px !important;
  -webkit-transition: all 0.3s !important;
  -moz-transition: all 0.3s !important;
  -ms-transition: all 0.3s !important;
  -o-transition: all 0.3s !important;
  transition: all 0.3s !important;
  padding: 12px 30px !important;
  text-transform: capitalize !important;
  font-weight: 300 !important;
  font-family: var(--website-font-family) !important;
  position: relative !important;
  box-shadow: none !important;
}

.effect-btn.btn-success-custom:hover, .effect-btn.btn-success-custom:focus, .effect-btn.btn-success-custom:active:focus, .effect-btn.btn-success-custom:active {
  border-color: #0C79B7 !important;
  color: #0C79B7 !important;
  background: var(--white) !important;
}

.btn-success-custom:hover, .btn-success-custom:focus, .btn-success-custom:active:focus, .btn-success-custom:active {
  color: var(--white) !important;
  background-color: #00882E !important;
}

.btn-success-custom:hover {
  color: #fff;
  background-color: #00882E;
  border-color: #0C79B7;
}

.btn-danger-custom:hover {
  color: #fff;
  background-color: #0C79B7;
  border-color: #0C79B7;
}

.effect-btn.btn-danger-custom:hover, .effect-btn.btn-danger-custom:focus, .effect-btn.btn-danger-custom:active:focus, .effect-btn.btn-danger-custom:active {
  color: #0C79B7 !important;
  background: var(--white) !important;
}

.btn-danger-custom:hover, .btn-danger-custom:focus, .btn-danger-custom:active:focus, .btn-danger-custom:active {
  color: var(--white) !important;
  background-color: #0C79B7 !important;
}

.btn-effect-custom:hover {
  color: #fff;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
}

*, ::after, ::before {
  box-sizing: border-box;
}

.effect-btn.btn-success-custom:after {
  border-color: #0C79B7 !important;
}

.effect-btn:hover:after, .effect-btn:focus:after, .effect-btn:active:focus:after, .effect-btn:active:after {
  transform: scale(1);
}

.btn-success-custom:after {
  border-color: #0C79B7 !important;
}

.squer-btn, .squer-btn:after {
  border-radius: 5px !important;
}

.btn-danger-custom:after {
  border-color: #0C79B7 !important;
}

.effect-btn.btn-danger-custom:after {
  border-color: #0C79B7 !important;
}

.effect-btn.btn-danger-custom {
  border-color: #0C79B7 !important;
}


.effect-btn:after {
  position: absolute;
  border: 2px solid;
  content: '';
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 30px;
  transform: scale(0);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/* ------------------------- */

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 17px !important;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-37.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}

/* DISABLED MAT-DATETIMEPICKER DATES COLOR */
.mat-calendar-body-disabled span {
  cursor: default;
  color: rgb(0 0 0 / 50%) !important;
}

/* --------------------------------------- */

/* CUSTOM CLASSES */
.card-footer-no-border {
  border: none !important;
}

.effect-btn.btn-success-custom:hover, .effect-btn.btn-success-custom:focus, .effect-btn.btn-success-custom:active:focus, .effect-btn.btn-success-custom:active
.card-footer-padding-as-body {
  padding-right: 25px;
}

/* -------------------- */

.handbook-btn-sizes {
  width: 10em !important;
  font-size: 14px !important;
}

/* LEFT ALIGN MAIN TEXT ON LOGIN PAGE */
.login-wrapper .loginbox .login-right h1 {
  text-align: left;
}

/* ------------------------- */

/* MAT CHECKBOX TEXT TO CENTER */
.mdc-form-field {
  display: flex;
  align-items: center;
}

.mdc-form-field label {
  margin-bottom: 0;
  padding-bottom: 0;
}

/* ------------------------- */

/* MAT HINT (ERROR MESSAGE) LEFT ALIGN */
.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
}

/* ------------------------- */

/* ONBOARDING CKEDITOR DATA DISPLAY STYLES */
.ckeditor-data blockquote {
  border-left: 5px solid #ccc;

  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.ckeditor-data figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  unicode-bidi: isolate;
}

.ckeditor-data figure table {
  border: 1px double #b3b3b3;
  border-collapse: collapse;
  border-spacing: 0;
  height: 100%;
  width: 100%;
}

.ckeditor-data figure table td {
  border: 1px solid #bfbfbf;
  min-width: 2em;
  padding: .4em;
  overflow-wrap: break-word;
  position: relative;
}

.ckeditor-data .table {
  display: table;
  margin: .9em auto;
}

.ckeditor-data .image {
  clear: both;
  display: table;
  margin: .9em auto;
  min-width: 50px;
  text-align: center;
}

.ckeditor-data .image img {
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}

.ckeditor-data .image > figcaption {
  --ck-color-image-caption-background: #f7f7f7;
  --ck-color-image-caption-text: #333;

  background-color: var(--ck-color-image-caption-background);
  caption-side: bottom;
  color: var(--ck-color-image-caption-text);
  display: table-caption;
  font-size: .75em;
  outline-offset: -1px;
  padding: .6em;
  word-break: break-word;
}

.ckeditor-data .media {
  clear: both;
  display: block;
  margin: .9em 0;
  min-width: 15em;
}

/* ------------------------- */

.mat-mdc-tab {
  --mat-tab-header-label-text-font: var(--website-font-family);
  font-family: var(--mat-tab-header-label-text-font);
  font-size: var(--mat-tab-header-label-text-size);
  letter-spacing: var(--mat-tab-header-label-text-tracking);
  line-height: var(--mat-tab-header-label-text-line-height);
  font-weight: var(--mat-tab-header-label-text-weight);
}

.mat-select-search-input {
  font-family: var(--website-font-family) !important;
}

.edit-icon {
  background-color: #eeeeee !important;
  border: 1px solid #e3e3e3 !important;
  color: #bbb !important;
  float: right !important;
  font-size: 12px !important;
  line-height: 24px !important;
  min-height: 26px !important;
  text-align: center !important;
  width: 26px !important;
  border-radius: 24px !important;
}

/* NG-SELECT BORDER STYLES */
.ng-select .ng-select-container.ng-appearance-outline:hover:after {
  border: 1px solid #5D8FC5 !important;
}

.ng-select-focused .ng-select-container.ng-appearance-outline:hover:after {
  border: 2px solid #0C79B7 !important;
}

/* ------------------------ */


/* MAT-MDC-SELECT ARROW STYLES */
.mat-mdc-select-arrow svg {
  left: 99% !important;
}

/* ------------------------ */

/* HANDBOOK LIST STYLES */
.ck-content ul li {
  list-style: disc;
}

.ckeditor-data ul li {
  list-style: disc;
}

/* ------------------------ */

.padding-top-0 {
  padding-top: 0 !important;
}

/* SCROLLBAR STYLES */
::-webkit-scrollbar-track {
  border-radius: 0.5rem;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.5rem;
  background-color: #C2C2C2;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #F5F5F5;
}

/* ------------------------ */

.table {
  margin-bottom: 40px !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #1D5795;
}

.row-without-padding {
  display: flex;
  flex-direction: row;
  padding-left: 22px;
}

.no-right-padding {
  padding-right: 0 !important;
}

.padding-left-25 {
  padding-left: 25px !important;
}

.no-padding {
  padding: 0 !important;;
}

.download-icon {
  font-size: 13px !important;
  color: #C4C4C4 !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  color: #1D5795 !important;
}

.delete-icon {
  color: #FF4A55 !important;
  transition: color 0.3s;
}

.delete-icon:hover {
  color: #f3777c !important;
}

.custom-menu-button {
  color: #1D5795 !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-size: 15px;
}

.mat-mdc-menu-panel {
  max-height: 300px;
}

.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #924AEF;
  --mdc-slider-focus-handle-color: #924AEF;
  --mdc-slider-hover-handle-color: #924AEF;
  --mdc-slider-active-track-color: #924AEF;
  --mdc-slider-inactive-track-color: #924AEF;
  --mdc-slider-with-tick-marks-active-container-color: transpartent;
  --mdc-slider-with-tick-marks-inactive-container-color: #924AEF;
  --mat-mdc-slider-ripple-color: #924AEF;
  --mat-mdc-slider-hover-ripple-color: transpartent;
  --mat-mdc-slider-focus-ripple-color: transpartent;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(103, 130, 255, 0.1);
}

/* PRIMERG CALENDAR STYLES */
.p-calendar {
  width: 100%;
  height: 60px;
}

.p-datepicker table {
  font-size: 14px;
}

.p-calendar .p-datepicker {
  min-width: 0;
}

.p-datepicker table td {
  padding: 0;
}

.p-datepicker .p-timepicker {
  padding: 0;
  font-size: 14px;
}

.p-datepicker .p-timepicker * {
  font-size: 14px;
}

.p-datepicker .p-datepicker-header {
  padding: 0;
}

:root {
  --font-family: var(--website-font-family);
}

.p-datepicker {
  top: 0 !important;
  position: fixed !important;
  left: 468px !important;
  display: initial !important;
}

.p-inputtext::placeholder {
  color: var(--mdc-outlined-text-field-label-text-color) !important;
  font-family: var(--mdc-outlined-text-field-label-text-font) !important;
  font-size: var(--mdc-outlined-text-field-label-text-size) !important;
  font-weight: var(--mdc-outlined-text-field-label-text-weight) !important;
  letter-spacing: var(--mdc-outlined-text-field-label-text-tracking) !important;
}

/* ----------------------- */

label.star:before {
  content: "\2605" !important;
  font-size: 24px;
}

label.star:hover {
  transform: rotate(-6deg) scale(1.3) !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: transparent !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #1D5795;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-mdc-form-field-error {
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-datepicker-content-container {
  background: #fff;
  box-shadow: 0 4px 10px 0 rgb(137 137 137 / 25%);
  border-radius: 7px;
  border: 0;
}

.mat-calendar-body-cell-content {
  color: #272e48;
  border: none !important;
  transition: all 0.2s;
}

.mat-calendar-body-cell-content:hover {
  color: #272e48;
  background: #e8e8e8;
}

.mat-calendar-body-today {
  background: rgba(103, 130, 255, 0.2);
}

.mat-calendar-body-selected {
  background: #d3d3d3;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: #272e48 !important;
}

.mat-datepicker-content-container .mat-mdc-form-field-subscript-wrapper {
  height: 0 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #1D5795 !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input {
  caret-color: #0C79B7 !important;
}

.mat-mdc-button:not(:disabled) {
  font-family: var(--website-font-family) !important;
}

.mat-calendar-arrow {
  margin: 0 0 3px 5px !important;
}

.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-mdc-form-field .mdc-text-field--filled .mat-mdc-form-field-infix input {
  font-family: var(--website-font-family) !important;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none !important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  color: #9b9b9b !important;
}

.ng-select .ng-select-container.ng-appearance-outline:after {
  border-radius: 5px;
  height: calc(100% - .5em);
  pointer-events: none;
  transition: border-color 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

table thead {
  background: #F1F5F9 !important;
}

.mat-calendar-table-header {
  background: transparent !important;
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
  font-family: var(--website-font-family);
  font-weight: var(--website-font-weight-normal);
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  font-family: var(--website-font-family) !important;
  color: #272e48 !important;
  caret-color: #0C79B7 !important;
}

.view-more-text {
  cursor: pointer;
  transition: color 0.5s;
  color: rgb(94, 94, 94) !important;
  font-weight: 300;
}

.view-more-text:hover {
  color: #3b3b3b !important;
}

.font-size-18 {
  font-size: 18px;
  font-weight: var(--website-font-weight-normal);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0C79B7;
  --mdc-linear-progress-track-color: rgba(12, 121, 183, 0.5);
}

.mat-datetimepicker-calendar-table-header {
  background: transparent !important;
}

.mat-mdc-tab-group {
  --mat-tab-header-active-ripple-color: transparent;
  --mat-tab-header-inactive-ripple-color: transparent;
}

.feedback-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 14px 0;
  width: 170px;
}

span :not(.modal-header) {
  font-weight: var(--website-font-weight-normal);
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-weight: var(--website-font-weight-normal) !important;
}

.green-snackbar {
  --mdc-snackbar-container-color: #E8FAEA;
  --mdc-snackbar-supporting-text-color: #12314F;
  border-radius: 5px;
}
.error-message {
   --mdc-snackbar-container-color: #FCF2F2;
   --mdc-snackbar-supporting-text-color: #F44336;
   border-radius: 5px;
   margin-top: -10px;
 }
.mdc-snackbar.error-message {
  border: 1px solid #F44336;
  border-radius: 5px;
}
.btn {
  font-weight: var(--website-font-weight-normal) !important;
}

.fc-theme-bootstrap a:not([href]) {
  font-weight: 400 !important;
}

.mdc-text-field--outlined .mdc-floating-label {
  font-weight: var(--website-font-weight-normal) !important;
}

.ws-font-weight {
  font-weight: var(--website-font-weight-normal) !important;
}

.text {
  font-weight: var(--website-font-weight-normal);
}

.error-msg {
  font-size: 16px;
  font-weight: var(--website-font-weight-normal);
  color: #272e48;
}
.discord-name {
  display: flex;
  color: #333333;
  float: left;
  font-weight: var(--website-font-weight-normal);
  width: 119px;
}
@media (max-width: 389px) {
  .header .header-left{
    display: flex;
    justify-content: flex-end;
  }
  .mobile-user-menu {
   display: none;
  }
  .icon-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .breadcrumb {
    font-size: 11px;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .breadcrumb::-webkit-scrollbar {
    display: none;
  }

  .breadcrumb-path .last-item {
    display: none;
  }
  .breadcrumb  a {
    font-size: 16px;
  }
  .breadcrumb-path .last-item {
    display: none;
  }
  .img-fluid {
    width: 110px;
  }
  .head-link-set li a {
    font-size: 12px;
    border: 50px;
    box-shadow: 0 4px 10px #89898940;
    width: 85px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .head-link-time-off {
    width: 200px;
  }
  .head-link-time-off li a {
    width: 60px
  }
  .head-link-set ul .head-link-time-off {
    display: flex;
    list-style-type: none;
    line-height: normal;
    font-family: Roboto, sans-serif;
    letter-spacing: .0178571429em;
    padding: 0;
  }
  .head-link-set .head-link-time-off li a.active {
    background: #1D5795;
    color: #fff;
  }
  .head-link-set .head-link-time-off ul li {
    display: inline-block;
    background-color: #fff;
  }

  .head-link-set .head-link-time-off ul li:last-child {
    border-right: none;
  }
  .mat-typography {
    font-weight: 400;
    line-height: 20px;
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
  }
  .handbook-btn-sizes {
    width: 8em !important;
    font-size: 10px;
  }
  .card .card-header .card-title {
    font-size: 16px;
  }
  .welcome-link {
    height: 50px;
    padding: 10px 10px 0 10px;
    display: flex;
    align-items: center;
  }
  .logout[_ngcontent-ng-c3215218868] li[_ngcontent-ng-c3215218868] a[_ngcontent-ng-c3215218868] {
    padding: 9px 55px;
  }
  .add-badges {
    margin-bottom: 0rem !important;
  }
  .badge-img[_ngcontent-ng-c976833854] {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    cursor: pointer;
  }
  .personal-info li {
    display: -webkit-box;
    gap: 5px;
  }
  a.btn-add {
    text-align: center;
    padding: 10px;
  }
  .table tr td label.action_label {
    border: 1px solid #1665d8;
    color: #1665d8;
    font-size: 14px;
    padding: 9px 6px;
    border-radius: 5px;
    width: -webkit-fill-available !important;
    text-align: center;
  }
  .custom-addmember-button {
    margin-left: 9px;
  }
  .no-right-padding {
    display: none;
  }
  .mat-typography h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    font-family: Roboto, sans-serif;
    letter-spacing: .0125em;
    margin: 0 0 16px;
  }
    .icon-wrapper {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      flex-direction: column;
    }
    .breadcrumb  a {
      font-size: 13px;
    }
    .breadcrumb-path .last-item {
      display: none;
    }
    .card {
      margin-bottom: .9375rem
    }

    .page-wrapper > .content {
      padding: .9375rem .9375rem 0
    }

    .chat-window {
      margin-bottom: .9375rem
    }

    .card-body {
      padding: 1.25rem
    }

    .card-header {
      padding: .75rem 1.25rem
    }

    .card-footer {
      padding: .75rem 1.25rem
    }

    .page-header {
      margin-bottom: .9375rem
    }

    .sidebar-menu ul {
      padding: 25px 0 0
    }

    .pagination-lg .page-link {
      font-size: 1.2rem;
      padding: .5rem .625rem
    }

    .card-body-inner ul li {
      margin: 10px 0 15px;
    }

    .invoice-details {
      float: left;
      text-align: left
    }

    .fc-toolbar .fc-right {
      display: inline-block;
      float: none;
      margin: 10px auto 0;
      width: 100%;
      clear: both
    }

    a.btn-dash {
      margin-bottom: 15px
    }

    .fc-toolbar .fc-left {
      float: none;
      margin: 0 auto;
      width: 100%
    }

    .fc-toolbar .fc-center {
      display: inline-block;
      width: 100%;
      margin-top: 15px;
      text-align: center
    }

    .fc-toolbar .fc-center h2 {
      width: 100%
    }

    .chat-window .chat-cont-right .chat-header .media .media-body {
      display: none
    }

    .chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, .chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
      max-width: 160px
    }

    .page-header .breadcrumb {
      display: none
    }

    .form-week li {
      margin: 1px
    }

    .btn-apply {
      padding: 10px;
      font-size: 14px
    }

    .user-menu {
      display: none !important
    }

    .mobile-user-menu {
      display: none
    }

    .checkworking ul, .form-week ul {
      flex-wrap: wrap
    }

    .card-body .default-set ul {
      display: block !important
    }
  .text-center {
    color: #747475;
    min-width: 120px !important;
  }
  .nav  {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  .head-link-set {
    position: relative;
    max-height: 100px;
    overflow-y: auto;
    width: 280px;
    padding: 0;
    border-radius: 10px;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  .head-link-set {
    position: relative;
    max-height: 100px;
    overflow-y: auto;
    padding: 0;
    border-radius: 10px;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }

  .head-link-set.with-add-btn {
    width: 280px;
  }

  .head-link-set.without-add-btn {
    width: 170px;
  }

}
@media (min-width: 390px) and (max-width: 481px){
  .header .header-left{
    display: flex;
    justify-content: flex-end;
  }
  .mobile-user-menu {
    display: none;
  }
  .icon-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .breadcrumb {
    font-size: 11px;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .breadcrumb::-webkit-scrollbar {
    display: none;
  }

  .breadcrumb-path .last-item {
    display: none;
  }
  .breadcrumb  a {
    font-size: 16px;
  }
  .breadcrumb-path .last-item {
    display: none;
  }
  .img-fluid {
    width: 110px;
  }
  .head-link-set li a {
    font-size: 13px;
    border: 50px;
    box-shadow: 0 4px 10px #89898940;
  }
  .head-link-set ul {
    display: flex;
    list-style-type: none;
    line-height: normal;
    font-family: Roboto, sans-serif;
    letter-spacing: .0178571429em;
    padding: 0;
  }
  .head-link-set li a.active {
    background: #1D5795;
    color: #fff;
  }
  .head-link-set ul li {
    display: inline-block;
    background-color: #fff;
  }

  .head-link-set ul li:last-child {
    border-right: none;
  }
  .mat-typography {
    font-weight: 400;
    line-height: 20px;
  }
  .card-footer {
    display: flex;
    justify-content: space-between;
  }
  .handbook-btn-sizes {
    width: 8em !important;
    font-size: 10px;
  }
  .card .card-header .card-title {
    font-size: 16px;
  }
  .welcome-link {
    height: 50px;
    padding: 10px 10px 0 10px;
    display: flex;
    align-items: center;
  }
  .logout li a {
    padding: 9px 55px;
  }
  .add-badges {
    margin-bottom: 0rem !important;
  }
  .badge-img[_ngcontent-ng-c976833854] {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    cursor: pointer;
  }
  .personal-info li {
    display: -webkit-box;
    gap: 5px;
  }
  a.btn-add {
    text-align: center;
    padding: 10px;
  }
  .table tr td label.action_label {
    border: 1px solid #1665d8;
    color: #1665d8;
    font-size: 14px;
    padding: 9px 6px;
    border-radius: 5px;
    width: -webkit-fill-available !important;
    text-align: center;
  }
  .custom-addmember-button {
    margin-left: 9px;
  }
  .no-right-padding {
    display: none;
  }
  .card-body-inner ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin-top: 10px;
  }
  .mat-typography h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    font-family: Roboto, sans-serif;
    letter-spacing: .0125em;
    margin: 0 0 16px;
  }
  .icon-wrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .breadcrumb  a {
    font-size: 11px;
  }
  .breadcrumb-path .last-item {
    display: none;
  }
  .head-link-set ul {
    display: flex;
    list-style-type: none;
    line-height: normal;
    font-family: Roboto, sans-serif;
    letter-spacing: .0178571429em;
    padding: 0;
  }
  .head-link-set li a.active {
    background: #1D5795;
    color: #fff;
  }
  .head-link-set ul li {
    display: inline-block;
    border-radius: 8px;
  }

  .head-link-set ul li:last-child {
    border-right: none;
  }
  .mat-typography {
    font-weight: 400;
    line-height: 20px;
  }
  .card {
    margin-bottom: .9375rem
  }

  .page-wrapper > .content {
    padding: .9375rem .9375rem 0
  }

  .chat-window {
    margin-bottom: .9375rem
  }

  .card-body {
    padding: 1.25rem
  }

  .card-header {
    padding: .75rem 1.25rem
  }

  .card-footer {
    padding: .75rem 1.25rem
  }

  .page-header {
    margin-bottom: .9375rem
  }

  .sidebar-menu ul {
    padding: 25px 0 0
  }

  .pagination-lg .page-link {
    font-size: 1.2rem;
    padding: .5rem .625rem
  }

  .card-body-inner ul li {
    margin: 0 0 15px
  }

  .invoice-details {
    float: left;
    text-align: left
  }

  .fc-toolbar .fc-right {
    display: inline-block;
    float: none;
    margin: 10px auto 0;
    width: 100%;
    clear: both
  }

  a.btn-dash {
    margin-bottom: 15px
  }

  .fc-toolbar .fc-left {
    float: none;
    margin: 0 auto;
    width: 100%
  }

  .fc-toolbar .fc-center {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
    text-align: center
  }

  .fc-toolbar .fc-center h2 {
    width: 100%
  }

  .chat-window .chat-cont-right .chat-header .media .media-body {
    display: none
  }

  .chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-name, .chat-window .chat-cont-left .chat-users-list a.media .media-body > div:first-child .user-last-chat {
    max-width: 160px
  }

  .page-header .breadcrumb {
    display: none
  }

  .form-week li {
    margin: 1px
  }

  .btn-apply {
    padding: 10px;
    font-size: 14px
  }

  .user-menu {
    display: none !important
  }

  .mobile-user-menu {
    display: none
  }

  .checkworking ul, .form-week ul {
    flex-wrap: wrap
  }

  .card-body .default-set ul {
    display: block !important
  }
  .text-center {
    color: #747475;
    min-width: 120px !important;
  }
  .nav  {
    display: flex;
    flex-wrap: nowrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
}
@media (min-width: 482px) and (max-width: 600px) {
  .card-body-inner ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin-top: 10px;
  }
}
